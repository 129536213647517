import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getApplicationData,
  verifyApplication,
  updateApplicationStatus,
  updateEmploymentData,
  updateSelectedTradelines,
} from 'thunks';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { CurrentFlow } from 'enums/CurrentFlow';
import { CompanyProduct } from 'enums/CompanyProduct';
import { UtmSource } from 'enums/UtmTagName';

import { VerificationResult } from 'api/LoanOfferApi';

export interface HardOfferData {
  payOff: Tradeline[];
  keepIt: Tradeline[];
  cantConsolidate: Tradeline[];
  payOffTotal: HardOfferTotal;
  planneryLoan: HardOfferTotal;
  offerSummary: HardOfferSummary;
}

export enum DebtRecommendation {
  KeepIt = 'keep-it',
  PayOff = 'pay-off',
}

export interface DebtSummary {
  [LoanType.PersonalLoan]: Debt;
  [LoanType.CreditCard]: Debt;
  [LoanType.StudentLoan]: Debt;
  [LoanType.AutoLoan]: Debt;
  [LoanType.Mortgage]: Debt;
}

export interface Debt {
  name: string;
  totalBalance: number | null;
  apr: number | null;
  recommendation: DebtRecommendation;
  tradelines: Tradeline[];
  cardOffer?: CardOffer;
}

interface CardOffer {
  apr: number;
  totalInterest: number;
  primaryCardId: string;
}

export interface HardOfferTotal {
  amount: number;
  apr: number;
  term: number;
  financeCharge: number;
  monthlyPayment: number;
}

export interface HardOfferSummary {
  loanAmount: number;
  moneySaved: number;
  monthsSaved: number;
}

export enum LoanType {
  PersonalLoan = 'personal-loan',
  CreditCard = 'credit-card',
  MedicalLoan = 'medical-loan',
  StudentLoan = 'student-loan',
  AutoLoan = 'auto-loan',
  Mortgage = 'mortgage',
  Other = 'other',
}

export enum LoanTypeHumanized {
  PersonalLoan = 'Personal Loan',
  CreditCard = 'Credit Card',
  MedicalLoan = 'Medical Loan',
  StudentLoan = 'Student Loan',
  AutoLoan = 'Auto Loan',
  Mortgage = 'Mortgage',
  Other = 'Other',
}

export enum Recommendation {
  PayOff = 'Pay Off',
  KeepIt = 'Keep it as is',
  CantConsolidate = "Can't consolidate",
}

export enum TradeLineStatus {
  Open = 'open',
  Closed = 'closed',
  Unknown = 'unknown',
}

export enum TradeLineErrCode {
  MissingTerm = 'missing-term',
  MissingBalance = 'missing-balance',
  MissingMonthlyPayment = 'missing-monthly-payment',

  EstimatedApr = 'estimated-apr',
  AprOutOfRange = 'apr-out-of-range',

  CantCalculateCreditCardTerm = 'cant-calculate-credit-card-term',
  CreditCardTermOufOfRange = 'credit-card-term-out-of-range',
}

export enum Source {
  CBC = 'CBC',
  Method = 'Method',
  CBCAndMethod = 'CBC and Method',
}
export interface Tradeline {
  id: string;
  firm: string;
  opened: string | null;
  originalBalance: number | null;
  balance: number;
  monthlyPayment: number | null;
  loanType: LoanType;
  term: number | null;
  status: TradeLineStatus;
  apr: number;
  recommendation: Recommendation;
  selectedForConsolidation: boolean | null;

  errorCode: string[] | null;

  accountMask?: string | null;
  elapsedMonths?: number | null;
  remainingMonths?: number | null;
  totalInterest?: number | null;
  planneryInterest?: number | null;
  savings?: number | null;

  methodAccountId?: string;
  logoUrl?: string;
  weWillPay?: boolean;

  source: Source | null;
}

export interface TradeLinesReport {
  proposed: Tradeline[];
  current: Tradeline[];
  other: Tradeline[];
}

export interface CreateApplicationData {
  status: ApplicationStatusName;
  product: CompanyProduct;
  borrowerFirstName: string;
  borrowerLastName: string;
  borrowerStreetAddress: string;
  borrowerCity: string;
  borrowerStateOrProvince: string;
  borrowerZipOrPostalCode: string;
  borrowerDateOfBirth: string;
  borrowerEmail: string;
  phoneNumber: string;
  loanAmountRange: string;
  creditScoreRange: string;
}

export interface GetApplicationData {
  id: string;
  status: ApplicationStatusName;
  currentFlow: CurrentFlow;
  borrowerId: string;
  borrowerCitizenship?: string;
  borrowerFirstName?: string;
  borrowerLastName?: string;
  borrowerStateOrProvince?: string;
  apr?: number;
  calculatedLoanAmount?: number;
  loanTermInMonths?: number;
  amountPerPaycheck?: number;
  loanAmount?: number;
  firstDeductionDate?: string;
  monthlyLoanPayment?: number;
  borrowerEmail?: string;
  originationFee?: number;
  documentaryStampTaxFee?: number;
  plaidTokenLastUpdated?: string;
  bankName?: string;
  accountMask?: string;
  dateOfLoan?: string;
  feedbackExperience?: string;
  feedbackHowDidYouHear?: string;
  hardOffer?: string | HardOfferData;
  debtSummary?: string | DebtSummary;
  utmSource?: UtmSource;
  cardApplied?: boolean;
  cardReferralLink?: string;
  employer1: Employer1;
  manualDeductionEmailSent?: boolean;
  completedRepaymentAccountStep?: boolean;
  fullDepositSwitch?: boolean;
  creditScore?: number;
  hasSignedACHForms?: boolean;
  missedPaymentReason?: string;
  missedPaymentAmount?: number;
  missedPaymentDate?: string;
  lateFee?: number;
}

export interface Employer1 {
  employerName?: string;
  payFrequency?: string;
  logo?: string;
  linkItemId?: string;
  payrollProvider?: string;
}

export interface ApplicationStatusUpdateResponse {
  status: ApplicationStatusName;
}

export interface ApplicationUpdateCitizenshipResponse {
  citizenship: string;
}

interface ResumeProcessApplicationData {
  application?: GetApplicationData;
  isLoading: boolean;
  isApplicationStatusUpdating: boolean;
}

export interface CreateApplicationResponse {
  passed: boolean;
  data: {
    application_id: string;
  };
}

export interface GetApplicationDataResponse {
  application: GetApplicationData;
}

const initialState: ResumeProcessApplicationData = {
  isLoading: false,
  isApplicationStatusUpdating: false,
};

const applicationData = createSlice({
  name: 'applicationData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getApplicationData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      state.application = parseApplication(payload.application);
      state.isLoading = false;
    });
    builder.addCase(updateEmploymentData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      state.application = parseApplication(payload.application);
    });
    builder.addCase(getApplicationData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getApplicationData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateApplicationStatus.pending, (state) => {
      state.isApplicationStatusUpdating = true;
    });
    builder.addCase(updateApplicationStatus.rejected, (state) => {
      state.isApplicationStatusUpdating = false;
    });
    builder.addCase(
      updateApplicationStatus.fulfilled,
      (state, { payload }: PayloadAction<ApplicationStatusUpdateResponse>) => {
        state.isApplicationStatusUpdating = false;
        state.application!.status = payload.status;
      },
    );
    builder.addCase(updateSelectedTradelines.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateSelectedTradelines.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      updateSelectedTradelines.fulfilled,
      (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
        state.isLoading = false;
        if (payload) {
          state.application! = parseApplication(payload.application);
        }
      },
    );
    builder.addCase(verifyApplication.fulfilled, (state, { payload }: PayloadAction<VerificationResult>) => {
      if (payload.status === ApplicationStatusName.Rejected) {
        state.application!.status = ApplicationStatusName.Rejected;
      }
    });
  },
});

export default applicationData.reducer;

const parseApplication = (application: GetApplicationData): GetApplicationData => ({
  ...application,
  hardOffer: application.hardOffer ? JSON.parse(application.hardOffer as string) : null,
  debtSummary: application.debtSummary ? JSON.parse(application.debtSummary as string) : null,
});

const MINIMUM_CREDIT_SCORE = 580;
const SUPPORTED_STATES = ['FL', 'GA', 'NC'];
const MINIMUM_SAVINGS = 500;
const DONT_PROVIDE_OFFER_STATUSES = [
  ApplicationStatusName.Approved,
  ApplicationStatusName.Rejected,
  ApplicationStatusName.ManualReview,
];

export const getShouldOfferPlanneryLoan = (application: GetApplicationData): boolean => {
  const offerSummary = (application?.hardOffer as HardOfferData)?.offerSummary;

  return (
    SUPPORTED_STATES.includes(application?.borrowerStateOrProvince!) &&
    application?.creditScore! >= MINIMUM_CREDIT_SCORE &&
    offerSummary?.moneySaved > MINIMUM_SAVINGS &&
    !DONT_PROVIDE_OFFER_STATUSES.includes(application?.status!)
  );
};
