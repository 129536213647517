import React, { useEffect, useState } from 'react';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';
import FadeTransition from 'components/FadeTransition';
import Button from 'components/Button';
import { useLocation } from 'react-router-dom';
import { RoutePath } from 'enums/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'handlers';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getApplicationData, getLoanOffer } from 'thunks';
import { LoanOfferResponse } from 'handlers/loanOffer';
import { DataForGettingLoanOffer } from 'api/LoanOfferApi';
import { ConversionType, trackConversion, trackConversionLead } from 'utils/analytics';
import { setCreditReportData } from 'handlers/creditReport';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'hooks/useNavigate';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import StateContainer from 'components/StateContainer';
import { ErrorType } from 'components/Error/ErrorType';
import { getApplicationData as applicationData } from 'selectors/getApplicationData';

import styles from './CreatingOffer.module.scss';

interface LocationState {
  dataForGettingLoanOffer: DataForGettingLoanOffer;
  watcher: any;
}

const CreatingOffer = ({ handleNext }: any): JSX.Element => {
  const { showHardOfferUi } = useFlags();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { state: routerState } = useLocation();

  const { isCompleted } = useSelector((state: RootState) => state.loanOffer);
  const { address_line_1: street } = useSelector((state: RootState) => state.yourAddress);
  const { dataForGettingLoanOffer, watcher } = routerState as LocationState;

  const { application } = useSelector(applicationData);

  const [canMoveToCompleted, setCanMoveToCompleted] = useState<boolean>(
    application?.status === ApplicationStatusName.OfferAvailable ?? false,
  );

  useEffect(() => {
    let mounted = true;

    const generateOffer = async () => {
      if (canMoveToCompleted) {
        return;
      }

      let loanOfferResponse: LoanOfferResponse;

      try {
        trackConversion(ConversionType.LoanApplied);
        loanOfferResponse = await dispatchWithUnwrap(
          getLoanOffer({
            ...dataForGettingLoanOffer,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            updateCallback: (data) => {
              // TODO: show progress
            },
          }),
        );
      } catch (error) {
        navigate(RoutePath.Error, { state: { type: ErrorType.CantGenerateOffer } });
        return;
      }

      if (isCompleted && application?.status !== ApplicationStatusName.OfferAvailable) {
        handleNext(false);
      }

      if (loanOfferResponse.data.borrower_id) {
        analytics.identify(loanOfferResponse.data.borrower_id);
        (window as any).nid('setUserId', loanOfferResponse.data.application_id);
      }

      const isQualified = loanOfferResponse.data.credit_passed;

      if (loanOfferResponse.data.application_id) {
        await dispatchWithUnwrap(getApplicationData(loanOfferResponse.data.application_id!));
      }
      if (isQualified) {
        const { email, firstName, lastName, phoneNumber, zipCode, city, state } = dataForGettingLoanOffer.application;
        const countryCode = 'US';
        trackConversionLead({
          email,
          firstName,
          lastName,
          phoneNumber,
          street,
          city,
          state,
          zipCode,
          countryCode,
        });
        trackConversion(ConversionType.LoanAppliedAndQualified);
      }

      dispatch(setCreditReportData(watcher));

      if (!showHardOfferUi) {
        handleNext(false);
        return;
      }

      if (loanOfferResponse.data.application_status === ApplicationStatusName.ManualReview) {
        handleNext(false);
        return;
      }

      if (!loanOfferResponse.passed) {
        handleNext(false);
        return;
      }

      if (mounted) {
        setCanMoveToCompleted(true);
      }
    };

    generateOffer();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.fadeContainer}>
        <FadeTransition
          toggle={!canMoveToCompleted}
          firstComponent={<StateContainer icon={<LoadingSpinner />} title="We're calculating your savings..." />}
          secondComponent={
            <StateContainer icon={<CheckIcon />} title="You've been pre-approved for a Plannery loan!" />
          }
        />
      </div>
      {isCompleted && canMoveToCompleted && <Button onClick={() => handleNext(true)}>Next</Button>}
    </div>
  );
};

export default CreatingOffer;
