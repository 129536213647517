import React, { FC } from 'react';
import { useTransition, animated, config } from 'react-spring';

interface FadeTransitionProps {
  firstComponent: JSX.Element;
  secondComponent: JSX.Element;
  toggle: boolean;
  duration?: number;
}

const FadeTransition: FC<FadeTransitionProps> = ({ firstComponent, secondComponent, toggle, duration }) => {
  const transitions = useTransition(toggle, {
    initial: { position: 'absolute', opacity: 1 },
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      ...config.molasses,
      duration: duration || 500,
    },
  });

  return transitions(({ opacity }, item) =>
    item ? (
      <animated.div
        style={{
          position: 'absolute',
          opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
        }}
      >
        {firstComponent}
      </animated.div>
    ) : (
      <animated.div
        style={{
          position: 'absolute',
          opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
        }}
      >
        {secondComponent}
      </animated.div>
    ),
  );
};

export default FadeTransition;
