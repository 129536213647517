import React, { useEffect, useState, FocusEvent } from 'react';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import FormContainer from 'components/LoanForm/FormContainer';
import Input from 'components/Input';
import { YourContactVariable } from 'enums/LoanFormVariables';
import { useForm } from 'react-hook-form';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import Button from 'components/Button';
import { CheckboxLabel } from 'enums/CheckboxLabel';
import Checkbox from 'components/Checkbox';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { setYourContactData } from 'handlers/yourContact';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'handlers';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { CreateApplicationData } from 'handlers/applicationData';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { getPreQualificationData } from 'selectors/preQualificationData';
import { createApplication, getPreQualificationData as preQualificationData } from 'thunks';
import { PreQualificationResponse } from 'handlers/preQualificationResult';
import { getYourNameData } from 'selectors/yourName';
import { getYourBirthDateData } from 'selectors/yourBirthDate';
import { CompanyProduct } from 'enums/CompanyProduct';

import { METHOD_TESTING_PHONES } from 'utils/methodPhoneNumbers';

import styles from './YourContact.module.scss';

export const EMAIL_PATTERN = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/;
export const PHONE_NUMBER_LENGTH = 14;

export enum YourContactInputLabel {
  Email = 'Email',
  PhoneNumber = 'Phone Number',
}

const YourContact = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState<boolean>(false);
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const { birth_date: birthDate } = useSelector(getYourBirthDateData);

  const {
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    city,
    zip_code: zipCode,
    argyle_state: argyleState,
  } = useSelector((state: RootState) => state.yourAddress);

  const {
    borrower_state_or_province: borrowerState,
    credit_score_range: creditScoreRange,
    loan_amount_range: loanAmount,
  } = useSelector(getPreQualificationData);

  const { email, phone_number: phoneNumber } = useSelector((state: RootState) => state.yourContact);

  const {
    register,
    setError,
    watch,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [YourContactVariable.Email]: email,
      [YourContactVariable.PhoneNumber]: phoneNumber,
    },
  });
  const watcher = watch();

  const onPhoneNumberBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (!phoneNumberIsValid) {
      return setError(YourContactVariable.PhoneNumber, {
        message: getMessageForInvalidFields(YourContactInputLabel.PhoneNumber),
      });
    }

    trigger(event.target.name as YourContactVariable);
  };

  const handleContinue = async () => {
    setIsLoading(true);
    dispatch(setYourContactData(watcher));

    const result: PreQualificationResponse = await dispatchWithUnwrap(
      preQualificationData({
        borrower_state_or_province: argyleState,
      }),
    );
    handleNext(result.passed);
    if (!result.passed) {
      saveOutOfStateApplication();
    }
    setIsLoading(false);
  };

  const saveOutOfStateApplication = async () => {
    const applicationData: CreateApplicationData = {
      status: ApplicationStatusName.OutOfState,
      product: CompanyProduct.PayrollLoan,
      borrowerFirstName: firstName,
      borrowerLastName: lastName,
      borrowerStreetAddress: addressLine2 ? `${addressLine1} ${addressLine2}` : addressLine1,
      borrowerCity: city,
      borrowerStateOrProvince: argyleState || borrowerState || '',
      borrowerZipOrPostalCode: zipCode,
      borrowerDateOfBirth: birthDate,
      borrowerEmail: watcher[YourContactVariable.Email],
      phoneNumber: watcher[YourContactVariable.PhoneNumber],
      loanAmountRange: loanAmount || '',
      creditScoreRange: creditScoreRange || '',
    };

    await dispatchWithUnwrap(createApplication(applicationData));
  };

  useEffect(() => {
    register(YourContactVariable.Email, {
      required: getMessageForRequiredFields(YourContactInputLabel.Email),
      pattern: {
        message: getMessageForInvalidFields(YourContactInputLabel.Email),
        value: EMAIL_PATTERN,
      },
    });

    register(YourContactVariable.PhoneNumber, {
      required: getMessageForRequiredFields(YourContactInputLabel.PhoneNumber),
    });
  }, [register, watcher]);

  const formIsValid = isValid && checked;

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <FormContainer title="Where do we share your savings plan with you?" subtitle="">
          <div className={styles.inputs}>
            <Input
              label={YourContactInputLabel.Email}
              placeholder="Email"
              errorMessage={errors[YourContactVariable.Email]?.message}
              className={styles.formInput}
              name={YourContactVariable.Email}
              onChange={(event) => {
                setValue(YourContactVariable.Email, event.target.value.trim());
                trigger(YourContactVariable.Email);
              }}
              value={watcher[YourContactVariable.Email]}
              data-neuro-label="email"
            />

            <PhoneNumberInput
              label={YourContactInputLabel.PhoneNumber}
              placeholder="(XXX) XXX-XXXX"
              errorMessage={errors[YourContactVariable.PhoneNumber]?.message}
              className={styles.formInput}
              name={YourContactVariable.PhoneNumber}
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                onPhoneNumberBlur((event as unknown) as FocusEvent<HTMLInputElement>)
              }
              onChange={(value) => {
                if (value) {
                  setPhoneNumberIsValid(
                    METHOD_TESTING_PHONES.includes(value as string) || isValidPhoneNumber(value as string),
                  );
                } else {
                  setPhoneNumberIsValid(false);
                }
                setValue(YourContactVariable.PhoneNumber, value);
                trigger(YourContactVariable.PhoneNumber);
              }}
              value={watcher[YourContactVariable.PhoneNumber]}
              country="US"
              maxLength={PHONE_NUMBER_LENGTH}
            />

            <Checkbox
              label={CheckboxLabel.SharingContactsAgreement}
              className={styles.checkbox}
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
          </div>

          <Button disabled={!formIsValid || isLoading} onClick={handleContinue} isLoading={isLoading}>
            Next
          </Button>
        </FormContainer>
      </div>
    </>
  );
};

export default YourContact;
