import React from 'react';
import Button, { ButtonType } from 'components/Button/Button';

import styles from './OpenDDADone.module.scss';

const NextSteps = () => {
  const handleGetBack = () => {
    window.open('https://www.planneryapp.com/');
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.descriptionContainer}>
          <p className={styles.header}>
            As a final step, we are reviewing that your payroll deduction has been set up successfully.
          </p>
          <p className={styles.subtitle}>Thank you for your time.</p>
        </div>
      </div>
      <Button type={ButtonType.Secondary} onClick={handleGetBack} className={styles.button}>
        Back to Plannery homepage
      </Button>
    </div>
  );
};

export default NextSteps;
