import ErrorMessages from 'enums/ErrorMessages';
import RequestError from 'errors/RequestError';
import { failureNotification } from 'notifications';
import { Environments, getEnvironment } from 'utils/getEnvironment';

export type AccessTokenGetter = () => Promise<string | null>;

interface FetchResponse<T = any> extends Response {
  json<P = T>(): Promise<P>;
}

export interface IRequestErrors extends Error {
  response?: FetchResponse;
}

export enum FetchMethodType {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface IFetchOptions {
  headers?: Headers;
  resetDefaultHeaders?: boolean;
  method?: FetchMethodType;
  body?: any;
  statusChecker?: (res: Response, displayErrorMessage: boolean) => Promise<FetchResponse<Body>>;
  displayErrorMessage?: boolean;
}

const checkStatus = async (response: FetchResponse, displayErrorMessage: boolean): Promise<FetchResponse> => {
  if (response.ok) {
    return response;
  }

  let errorMessage = null;
  try {
    if (response.body) {
      // Avoids parsing a null body to json
      const body = await response.json();

      if (!displayErrorMessage && getEnvironment() === Environments.Production) {
        errorMessage = ErrorMessages.Default;
      } else {
        errorMessage = body.message || body.data?.error || body.error?.message || response.statusText;
      }

      failureNotification(errorMessage || ErrorMessages.Default);
    }
  } catch (error) {
    failureNotification(ErrorMessages.Default);
  }

  throw new RequestError(errorMessage, response.status);
};

const getBasicHeaders = () => {
  const headers = new Headers();

  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');

  return headers;
};

const getLastStreamMessage = (value: Uint8Array): any => {
  const decoder = new TextDecoder();
  const message = decoder.decode(value);

  const array = message.split('\n').filter((item) => item !== '');
  const lastMessage = array[array.length - 1];

  return JSON.parse(lastMessage);
};

export default class Api {
  constructor(private baseUrl?: string) {}

  protected async fetch<Body>(url: string, options?: IFetchOptions): Promise<Body> {
    const {
      headers: customHeaders,
      method = FetchMethodType.GET,
      body,
      resetDefaultHeaders,
      statusChecker = checkStatus,
      displayErrorMessage = false,
    } = options || {};

    const headers = resetDefaultHeaders ? new Headers() : getBasicHeaders();

    if (customHeaders) {
      customHeaders.forEach((value: string, header: string) => {
        headers.set(header, value);
      });
    }

    const userData = await fetch(this.baseUrl ? `${this.baseUrl}${url}` : url, {
      method,
      headers,
      body: body instanceof FormData ? body : JSON.stringify(body),
    });

    await statusChecker(userData, displayErrorMessage);

    // TODO: can't handle HTTP 204 No Content, because it tries to convert an empty body to json!
    return userData.json();
  }

  protected async *fetchStream<Body>(url: string, options?: IFetchOptions): AsyncGenerator<Body> {
    const {
      headers: customHeaders,
      method = FetchMethodType.GET,
      body,
      resetDefaultHeaders,
      statusChecker = checkStatus,
      displayErrorMessage = false,
    } = options || {};

    const headers = resetDefaultHeaders ? new Headers() : getBasicHeaders();

    if (customHeaders) {
      customHeaders.forEach((value: string, header: string) => {
        headers.set(header, value);
      });
    }

    const response = await fetch(this.baseUrl ? `${this.baseUrl}${url}` : url, {
      method,
      headers,
      body: body instanceof FormData ? body : JSON.stringify(body),
    });

    const reader = response.body!.getReader();

    let prev = await reader.read();
    let done = false;
    while (!done) {
      // eslint-disable-next-line no-await-in-loop
      const next = await reader.read();
      if (!next.done) {
        if (prev.value) {
          yield getLastStreamMessage(prev.value);
        }
        prev = next;
      }
      done = next.done;
    }

    await statusChecker(response, displayErrorMessage);
    return getLastStreamMessage(prev.value!);
  }
}
