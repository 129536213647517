import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { RootState } from 'handlers';
import { uploadDocuments } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import DropzoneWidget, { useFiles } from 'components/DropzoneWidget';
import { getApplicationData } from 'selectors/getApplicationData';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './FileUpload.module.scss';

const FilesPreview = React.lazy(() => import('components/FilesPreview'));

export const DROPBOX_EXTENSIONS = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'application/pdf'];

const renameFiles = (cb: (files: File[]) => void, filePrefix?: string) => (files: File[]) => {
  const prefix = filePrefix ? `${filePrefix} ` : '';
  return cb(files.map((file) => new File([file], `${prefix}${file.name}`, { type: file.type })));
};

const FileUpload = ({ flags, navigationInfo, handleNext }: FlowComponentType) => {
  const { handleAddFiles, files, handleRemoveFiles } = useFiles();
  const { id: applicationId } = useSelector(getApplicationData).application!;
  const { isApplicationStatusUpdating } = useSelector(getApplicationData);
  const { isLoading } = useSelector((state: RootState) => state.filesUpload);

  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const handleContinue = async () => {
    const filesForUpload: File[] = files.map((item) => item.file);

    await dispatchWithUnwrap(uploadDocuments({ applicationId, files: filesForUpload }));

    handleNext();
  };

  const prefix = flags.enableTreasuryPrimeIntegration ? 'Paystub' : 'Document';

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title={flags.enableTreasuryPrimeIntegration ? 'Upload Paystubs' : 'Upload Documents'}
        subtitle={
          flags.enableTreasuryPrimeIntegration
            ? 'Please upload your two most recent paystubs.'
            : 'Please upload the documents listed below:'
        }
        className={styles.formContainer}
      >
        {!flags.enableTreasuryPrimeIntegration && (
          <ul className={styles.documentsList}>
            <li className={styles.documentListItem}>Two most recent paystubs</li>
            <li className={styles.documentListItem}>Drivers License (front)</li>
            <li className={styles.documentListItem}>Drivers License (back)</li>
          </ul>
        )}
        <DropzoneWidget handleFiles={renameFiles(handleAddFiles, prefix)} extensions={DROPBOX_EXTENSIONS} />
        <ul className={styles.filesRequirementsList}>
          <li>Acceptable file formats are PDF, JPG, JPEG, GIF and PNG</li>
          <li>Maximum file size 10MB</li>
        </ul>
        {files.length ? (
          <Suspense fallback={<ReactLoading type="spin" color="#fff" />}>
            <FilesPreview files={files} handleRemove={handleRemoveFiles} />
          </Suspense>
        ) : (
          <></>
        )}
        <Button
          disabled={!files.length}
          className={styles.button}
          onClick={handleContinue}
          isLoading={isLoading || isApplicationStatusUpdating}
        >
          Next
        </Button>
      </FormContainer>
    </>
  );
};

export default withLDConsumer()(FileUpload);
