import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { LoanType, Source, Tradeline, TradeLineErrCode } from 'handlers/applicationData';
import CheckboxSmall from 'components/Checkbox/CheckboxSmall';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import { ReactComponent as CheckIcon } from 'images/check-icon.svg';

import Loader from 'components/Loader';

import { SelectedTradeline, TradelineNewState } from 'api/ApplicationDataApi';

import styles from './ConsolidationTable.module.scss';

interface ConsolidationTableProps {
  tradeLines: Tradeline[];
  editModeIsOn: boolean;
  selectedTradelines: Record<string, SelectedTradeline>;
  setSelectedTradelines: (selectedTradelines: Record<string, SelectedTradeline>) => void;
  setCurrentLoanAmount: Dispatch<SetStateAction<number>>;
  setCurrentNumberOfAccounts: Dispatch<SetStateAction<number>>;
}

const ConsolidationTable = ({
  tradeLines,
  editModeIsOn,
  selectedTradelines,
  setSelectedTradelines,
  setCurrentLoanAmount,
  setCurrentNumberOfAccounts,
}: ConsolidationTableProps) => {
  const selectedForConsolidation = tradeLines.filter((t) => t.selectedForConsolidation);
  const notSelectedForConsolidation = tradeLines.filter((t) => !t.selectedForConsolidation);

  const checkboxOnChange = useCallback(
    (tradeline: SelectedTradeline) => {
      const { id, balance, selectedForConsolidation: selected } = tradeline.tradeline;

      if (id in selectedTradelines) {
        const { [id]: omitted, ...rest } = selectedTradelines;
        setSelectedTradelines({ ...rest });
        if (selected) {
          setCurrentNumberOfAccounts((currentAmount: number) => currentAmount + 1);
          setCurrentLoanAmount((currentAmount: number) => currentAmount + balance);
        } else {
          setCurrentNumberOfAccounts((currentAmount: number) => currentAmount - 1);
          setCurrentLoanAmount((currentAmount: number) => currentAmount - balance);
        }
      } else {
        setSelectedTradelines({ ...selectedTradelines, [id]: tradeline });
        if (selected) {
          setCurrentLoanAmount((currentAmount: number) => currentAmount - balance);
          setCurrentNumberOfAccounts((currentAmount: number) => currentAmount - 1);
        } else {
          setCurrentNumberOfAccounts((currentAmount: number) => currentAmount + 1);
          setCurrentLoanAmount((currentAmount: number) => currentAmount + balance);
        }
      }
    },
    [selectedTradelines, setSelectedTradelines],
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={clsx(styles.column, styles.webHeaderColumn)} />
        <div className={styles.column}>Balance</div>
        <div className={styles.column}>Monthly Payment</div>
        <div className={styles.column}>Remaining Months</div>
        <div className={styles.column}>APR</div>
        <div className={clsx(styles.column, styles.webHeaderColumn)}>Savings</div>
      </div>

      <div className={styles.content}>
        {selectedForConsolidation.map((line, index) => (
          <TableRow
            key={line.id || `${line.firm?.trim()}-${line.balance}-${index}`}
            tradeLine={line}
            checkboxOnChange={checkboxOnChange}
            editModeIsOn={editModeIsOn}
          />
        ))}
        {notSelectedForConsolidation.map((line, index) => (
          <TableRow
            key={line.id || `${line.firm?.trim()}-${line.balance}-${index}`}
            tradeLine={line}
            checkboxOnChange={checkboxOnChange}
            editModeIsOn={editModeIsOn}
          />
        ))}
      </div>
    </div>
  );
};

interface TableRowProps {
  tradeLine: Tradeline;
  checkboxOnChange: (selectedTradeline: SelectedTradeline) => void;
  editModeIsOn: boolean;
}

const maybeAddAnAsterisk = (errorCode: any, wontConsolidate: boolean, tradeline: Tradeline) => {
  if (tradeline.source === Source.CBC) {
    return '*';
  }

  if (tradeline.source === Source.Method && tradeline.loanType !== LoanType.CreditCard) {
    return '*';
  }

  if (errorCode && Array.isArray(errorCode) && errorCode.includes(TradeLineErrCode.EstimatedApr) && !wontConsolidate) {
    return '*';
  }

  return '';
};

interface TradelineFirmProps {
  tradeLine: Tradeline;
  checkboxOnChange: (selectedTradeline: SelectedTradeline) => void;
  editModeIsOn: boolean;
}

const TradelineFirm = ({ editModeIsOn, checkboxOnChange, tradeLine }: TradelineFirmProps) => {
  const { firm, accountMask, loanType, selectedForConsolidation } = tradeLine;

  const formatType = (type: string) => type.split('-').join(' ');
  const formattedFirm = `${firm} - ${formatType(loanType)} ${accountMask ?? ''}`;

  const [checked, setChecked] = useState(!!selectedForConsolidation);

  const { isLoading } = useSelector((state: RootState) => state.applicationData);

  useEffect(() => {
    if (!editModeIsOn) {
      setChecked(!!selectedForConsolidation);
    }
  }, [editModeIsOn]);

  return (
    <>
      {editModeIsOn &&
        (isLoading ? (
          <div>
            <Loader color="#9d86f9" size={25} />
          </div>
        ) : (
          <CheckboxSmall
            onChange={() => {
              checkboxOnChange({
                tradeline: tradeLine,
                newState: checked ? TradelineNewState.Unselected : TradelineNewState.Selected,
              });
              setChecked(!checked);
            }}
            checked={checked}
            label=""
            className={styles.checkbox}
            disabled={isLoading}
          />
        ))}
      {!editModeIsOn && (
        <div className={styles.staticCheckbox}>
          <CheckIcon
            className={clsx({
              [styles.wontConsolidate]: !selectedForConsolidation,
            })}
          />
        </div>
      )}
      <div className={styles.creditorName}>{formattedFirm}</div>
    </>
  );
};

const TableRow = ({ tradeLine, checkboxOnChange, editModeIsOn }: TableRowProps) => {
  const { savings, balance, monthlyPayment, term, apr, errorCode, selectedForConsolidation } = tradeLine;

  const showSavings = selectedForConsolidation && savings! > 0;

  return (
    <div className={styles.rowContainer}>
      <div className={styles.rowHeading}>
        <div className={clsx(styles.group, !showSavings && styles.groupFullWidth)}>
          <TradelineFirm checkboxOnChange={checkboxOnChange} editModeIsOn={editModeIsOn} tradeLine={tradeLine} />
        </div>

        {showSavings && <div className={styles.saveAmount}>Save {formatMonetaryAmount(savings!)}</div>}
      </div>
      <div className={styles.rowContent}>
        <div className={clsx(styles.group, styles.webContentColumn)}>
          <TradelineFirm checkboxOnChange={checkboxOnChange} editModeIsOn={editModeIsOn} tradeLine={tradeLine} />
        </div>

        <div aria-label="balance" className={styles.tableCol}>
          {formatMonetaryAmount(balance)}
        </div>
        <div aria-label="montlhy payment" className={styles.tableCol}>
          {formatMonetaryAmount(monthlyPayment!)}
        </div>
        <div aria-label="term" className={styles.tableCol}>
          {term || 'N/A'}
        </div>
        <div aria-label="apr" className={styles.tableCol}>
          {apr ? `${apr}%` : 'N/A'}
          {maybeAddAnAsterisk(errorCode, !selectedForConsolidation, tradeLine)}
        </div>

        {/* Savings column for web version */}
        <div className={clsx(styles.tableCol, styles.savings)}>
          {!savings || savings <= 0 ? '-' : formatMonetaryAmount(savings)}
        </div>
      </div>
    </div>
  );
};

export default ConsolidationTable;
