import { GeocoderAddressComponent } from 'types/GooglePlacesService';
import { GeocoderAddressComponentType } from 'enums/GeocoderAddressComponentType';

export const getParsedAddress = (address: GeocoderAddressComponent[]) => {
  const parsedAddress = {
    state: '',
    zip: '',
    city: '',
    address: '',
  };

  address.find((item) => {
    if (item.types.includes(GeocoderAddressComponentType.PostalCode)) {
      parsedAddress.zip = item.long_name;
    }

    if (
      item.types.includes(GeocoderAddressComponentType.Locality) ||
      item.types.includes(GeocoderAddressComponentType.Sublocality)
    ) {
      parsedAddress.city = item.long_name;
    }

    if (item.types.includes(GeocoderAddressComponentType.StreetNumber)) {
      parsedAddress.address += `${item.long_name} `;
    }

    if (item.types.includes(GeocoderAddressComponentType.Route)) {
      parsedAddress.address += item.long_name;
    }

    if (item.types.includes(GeocoderAddressComponentType.AdministrativeAreaLevel1)) {
      parsedAddress.state += item.short_name;
    }

    return undefined;
  });

  return parsedAddress;
};
