import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDocumentsApi, DataForUploadingDocuments } from 'api/DocumentsApi';

export const createUploadDocumentsThunk = (api: ApplicationDocumentsApi) =>
  createAsyncThunk('documents/upload', async (data: DataForUploadingDocuments, thunkApi) => {
    try {
      await api.uploadDocuments(data);
    } catch (error) {
      thunkApi.rejectWithValue(error.response.data);

      throw error;
    }
  });
