import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import AppRoutes from 'routes';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ScriptsLoader } from 'components/ScriptsLoader/ScriptsLoader';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { ToastContainer } from 'react-toastify';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { assignFeatureFlags } from 'hooks/useFeatureFlags';

import store from './store';

import 'react-toastify/dist/ReactToastify.min.css';
import './index.scss';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

initializeAnalytics();
assignFeatureFlags();

(async () => {
  let app = (
    <ScriptsLoader>
      <Provider store={store}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
        <ToastContainer limit={1} />
      </Provider>
    </ScriptsLoader>
  );
  if (process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID) {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
      user: {
        anonymous: true,
        custom: {
          // We use this for excluding internal production users (prod.planneryapp.com) from experiments.
          hostname: window.location.hostname,
        },
      },
    });
    app = <LDProvider>{app}</LDProvider>;
  }
  ReactDOM.render(app, document.getElementById('root'));
})();

function initializeAnalytics() {
  window.analytics = AnalyticsBrowser.load({
    writeKey: process.env.REACT_APP_ANALYTICS_WRITE_KEY as string,
  });

  parseCrossDomainConversionTrackingParams();

  analytics.page();
}

function parseCrossDomainConversionTrackingParams() {
  const urlParams = new URLSearchParams(window.location.search);

  // This tracks users with the same ID between the marketing site and the product.
  const anonymousId = urlParams.get('anonymous_id');
  if (anonymousId) {
    analytics.setAnonymousId(anonymousId);
  }

  // This is the Facebook click identifier which is set if the user clicked a Facebook ad.
  // We pass this from the marketing site to the product.
  const fbc = urlParams.get('fbc');

  // This is the Facebook pixel identifier that is passed from the marketing site to the product.
  const fbp = urlParams.get('fbp');

  if (fbc || fbp) {
    analytics.identify({ ...(fbc && { fbc }), ...(fbp && { fbp }) });
  }
}
