import React, { FC } from 'react';
import clsx from 'clsx';
import { Message } from 'react-hook-form';

import InputError from 'components/InputError';
import useLayoutTheme from 'hooks/useLayoutTheme';

import defaultStyles from './Input.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: Message | undefined;
  inputClassName?: string;
}

const Input: FC<InputProps> = ({ value, className, label, errorMessage, inputClassName, onBlur, ...restProps }) => {
  const { styles } = useLayoutTheme();
  return (
    <div className={clsx(defaultStyles.inputContainer, className)}>
      {label && <p className={clsx(defaultStyles.label, styles.inputLabel)}>{label}</p>}
      <input
        value={value || ''}
        autoComplete="off"
        {...restProps}
        className={clsx(
          defaultStyles.input,
          styles.input,
          inputClassName,
          !!errorMessage && defaultStyles.error,
          !!restProps.readOnly && defaultStyles.readOnly,
        )}
        onBlur={onBlur}
      />
      {!!errorMessage && <InputError errorMessage={errorMessage} />}
    </div>
  );
};

export default Input;
