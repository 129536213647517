import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { DropzoneFile } from './DropzoneWidget';

const useFiles = () => {
  const [files, setFiles] = useState<DropzoneFile[]>([]);

  const handleAddFiles = (newFiles: File[]) => {
    const dropZoneFiles: DropzoneFile[] = newFiles.map((file: File) => ({
      file,
      key: uuidv4(),
      blobLink: URL.createObjectURL(file),
    }));

    setFiles([...files, ...dropZoneFiles]);
  };

  const handleRemoveFiles = (removedIndex: number) => () => {
    files.splice(removedIndex, 1);
    setFiles([...files]);
  };

  return { files, setFiles, handleAddFiles, handleRemoveFiles };
};

export default useFiles;
