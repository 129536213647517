import React from 'react';
import Button from 'components/Button';
import { ReactComponent as CheckIcon } from 'images/check-icon-green.svg';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './VerifyDetailsIntro.module.scss';

const VerifyDetailsIntro = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => (
  <>
    <FormNavigation {...navigationInfo} />
    <div className={styles.container}>
      <CheckIcon className={styles.checkIcon} />
      <p className={styles.text}>
        Next, we’ll verify your details so you can get your loan and pay off your expensive debt.
      </p>
      <Button onClick={handleNext} className={styles.button}>
        Next
      </Button>
    </div>
  </>
);

export default VerifyDetailsIntro;
