import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getYourContactData } from 'selectors/getYourContact';
import { getMethodAuthData } from 'selectors/methodAuth';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useCurrentFlow from 'hooks/useCurrentFlow';
import { elementTokenThunk } from 'thunks';
import { getYourNameData } from 'selectors/yourName';
import { useMethod } from 'react-method-elements';
import { Environments, getEnvironment } from 'utils/getEnvironment';
import { setCxnId } from 'handlers/methodAuth';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { ErrorType } from 'components/Error/ErrorType';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';

import { ReactComponent as Plus } from 'images/plus.svg';
import { ReactComponent as PlanneryLogo } from 'images/plannery-icon.svg';
import MethodIcon from 'images/method-icon.png';

import styles from './MethodAuth.module.scss';

const getMethodEnv = () => {
  const env = getEnvironment();
  switch (env) {
    case Environments.Production:
      return 'production';
    case Environments.Staging:
      return 'dev';
    default:
      throw Error(`[Method env] ENVIRONMENT should be set to prod or staging but is: ${env}`);
  }
};

interface SuccessPayload {
  cxn_id: string;
  authenticated: true;
  entity_id: string;
  accounts: string[];
}

const MethodAuth = ({ navigationInfo }: FlowComponentType) => {
  const { elementToken, isLoading } = useSelector(getMethodAuthData);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldOpen, setShouldOpen] = useState(false);
  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();

  const { isCardFlow, isFinancialCheckupFlow } = useCurrentFlow();

  const method = useMethod({
    env: getMethodEnv(),
    onSuccess: (payload) => {
      const { cxn_id: cxnId } = (payload as unknown) as SuccessPayload;
      dispatch(setCxnId({ cxnId }));
      navigate(RoutePath.Analyzing);
    },
    onError: () => {
      navigate(RoutePath.Error, { state: { type: ErrorType.InvalidMethodAuthentication } });
    },
    onEvent: (message: MessageEvent) => {
      analytics.track('Method Event', message.data);
    },
  });

  const handleClick = () => {
    setShouldOpen(true);
    analytics.track('Method Connect Button Clicked');
  };

  useEffect(() => {
    const createElementToken = async () => {
      await dispatchWithUnwrap(
        elementTokenThunk({
          email,
          phoneNumber,
          firstName,
          lastName,
        }),
      );
    };

    if (!isLoading && !elementToken && phoneNumber && firstName && lastName) {
      createElementToken();
    }
  }, [phoneNumber, firstName, lastName]);

  useEffect(() => {
    if (method && elementToken && !isOpen && shouldOpen) {
      setIsOpen(true);
      setShouldOpen(false);
      method!.open(elementToken);
    }
  }, [method, elementToken, isOpen, shouldOpen]);

  let title = '';

  if (isCardFlow) {
    title = 'Check Eligibility';
  }

  if (isFinancialCheckupFlow) {
    title = 'Connect';
  }

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title={title}
        subtitle="Plannery partners with Method Financial to securely access your financial data."
      >
        <div className={styles.items}>
          <div className={styles.item}>
            <div className={styles.icon}>
              <PlanneryLogo />
            </div>
            <div className={styles.text}>Plannery</div>
          </div>
          <div className={styles.plus}>
            <Plus />
          </div>
          <div className={styles.item}>
            <div className={styles.icon}>
              <img src={MethodIcon} alt="Method Financial" />
            </div>
            <div className={styles.text}>Method Financial</div>
          </div>
        </div>

        <p className={styles.subtext}>
          You may be asked additional security questions. Your data is kept confidential with Plannery.
        </p>

        <Button onClick={handleClick} isLoading={!elementToken && shouldOpen}>
          Next
        </Button>
      </FormContainer>
    </>
  );
};

export default MethodAuth;
