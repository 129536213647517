export const getPlanneryApiUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const apiRevision = urlParams.get('apiRevision');

  let apiUrl;
  if (apiRevision) {
    apiUrl = `https://api.staging.planneryapp.com/rev.${apiRevision}`;
  } else {
    apiUrl = process.env.REACT_APP_API_URL;
  }

  return apiUrl;
};
