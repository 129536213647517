import React, { FC } from 'react';
import clsx from 'clsx';
import useLayoutTheme from 'hooks/useLayoutTheme';

import defaultStyles from './FormContainer.module.scss';

interface FormContainerProps {
  title: string;
  subtitle?: string;
  className?: string;
}

const FormContainer: FC<FormContainerProps> = ({ children, title, subtitle, className }) => {
  const { styles } = useLayoutTheme();

  return (
    <div className={clsx(defaultStyles.container, styles.formContainer, className)}>
      <div>
        <h1 className={defaultStyles.title}>{title}</h1>
        {subtitle && <p className={defaultStyles.subtitle}>{subtitle}</p>}
      </div>
      {children}
    </div>
  );
};

export default FormContainer;
