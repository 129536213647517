import React, { FC } from 'react';
import Loader from 'components/Loader';
import { ReactComponent as InfoPurpleIcon } from 'images/info-purple-icon.svg';
import ReactTooltip from 'react-tooltip';
import clsx from 'clsx';

import styles from './OfferCard.module.scss';

export enum OfferType {
  Savings = 'savings',
  PriceMatched = 'price-matched',
  Market = 'market',
}

export enum CardState {
  Normal,
  Selected,
  Disabled,
}

interface OfferCardProps {
  offerType: OfferType;
  state?: CardState;
  title: string;
  monthAmount?: number;
  months: number;
  apr?: number;
  savings?: number;
  loading?: boolean;
  aprMatched?: number;
  showSavings?: boolean;
  showTerm?: boolean;
}

const OfferCard: FC<OfferCardProps> = ({
  offerType,
  state,
  title,
  monthAmount,
  months,
  apr,
  savings,
  loading,
  aprMatched,
  showSavings,
  showTerm,
}): JSX.Element => {
  const getTooltipText = () => {
    switch (offerType) {
      case OfferType.Savings:
        return savings
          ? `The total cost of this loan is $${savings.toFixed(0).toLocaleString()} less than a  market rate loan.`
          : '';
      case OfferType.PriceMatched:
        return aprMatched ? 'We matched the best price we can find on a market rate loan.' : '';
      case OfferType.Market:
        return 'Compare the Plannery loan with this similar market rate loan.';
      default:
        return '';
    }
  };

  const getSavingsText = () => {
    switch (offerType) {
      case OfferType.Savings:
        return savings ? `Save $${savings.toFixed(0).toLocaleString()}` : '';
      case OfferType.PriceMatched:
        return aprMatched ? 'Price Match' : '';
      default:
        return '';
    }
  };

  const isMarket = offerType === OfferType.Market;

  const classNames = [styles.offerCard];
  if (state === CardState.Selected) {
    classNames.push(styles.selectedCard);
  } else if (state === CardState.Disabled) {
    classNames.push(styles.disabledCard);
  }

  return (
    <div className={classNames.join(' ')}>
      {!isMarket && loading && showSavings !== false ? (
        <Loader color="#9d86f9" size={25} />
      ) : (
        showSavings !== false && (
          <div className={styles.savings}>
            {!isMarket ? (
              <>
                <span className={styles.savingsText}>{getSavingsText()}</span>
                <InfoPurpleIcon data-for={`${offerType}-info`} data-tip style={{ outline: 0 }} />
                <ReactTooltip id={`${offerType}-info`} place="bottom" className={styles.tooltip} arrowColor="#6d7784">
                  <span className={styles.tooltipText}>{getTooltipText()}</span>
                </ReactTooltip>
              </>
            ) : (
              <span className={styles.comparedToText}>Compared to</span>
            )}
          </div>
        )
      )}
      <div className={styles.titleContainer}>
        <span
          className={clsx(styles.title, {
            [styles.highlighted]: !isMarket,
          })}
        >
          {title}
        </span>
      </div>
      <div className={styles.group}>
        {loading ? (
          <Loader color="#9d86f9" size={25} />
        ) : (
          <span className={styles.primaryText}>{monthAmount ? `$${monthAmount.toLocaleString()}` : '-'}</span>
        )}
        <span className={styles.secondaryText}>Monthly Cost</span>
      </div>
      {showTerm !== false && (
        <div className={styles.group}>
          <span className={styles.primaryText}>{months}</span>
          <span>Months</span>
        </div>
      )}
      <div className={styles.group}>
        {loading ? (
          <Loader color="#9d86f9" size={25} />
        ) : (
          <span className={styles.primaryText}>
            <span className={aprMatched ? styles.discardedText : ''}>{apr ? `${apr}%` : '-'}</span>
            {aprMatched && <span className={styles.secondaryText}>{aprMatched}%</span>}
          </span>
        )}
        <span className={styles.secondaryText}>Rate</span>
      </div>
    </div>
  );
};

export default OfferCard;
