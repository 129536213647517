import React, { ChangeEvent, useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useScript } from 'components/ScriptsLoader/ScriptsLoader';
import { YourDetailsVariable } from 'enums/LoanFormVariables';
import { getParsedAddress } from 'utils/getParsedAddress';
import { getYourAddressData } from 'selectors/yourAddress';
import { useForm } from 'react-hook-form';
import { getMessageForRequiredFields } from 'utils/errors';
import { STATE_OPTIONS } from 'utils/getCountryStateLabel';
import { setYourDetailsData } from 'handlers/yourAddress';
import { setStateData } from 'handlers/preQualificationData';
import { RootState } from 'handlers';
import { getPreQualificationData as preQualificationData } from 'selectors/preQualificationData';

import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import Input from 'components/Input';
import NumberInput from 'components/NumberInput';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import InputSelect from 'components/InputSelect';

import styles from './YourAddress.module.scss';

enum YourDetailsInputLabel {
  AddressLine1 = 'Address Line 1',
  AddressLine2 = 'Address Line 2 (Optional)',
  City = 'City',
  ZipCode = 'Zip Code',
  State = 'State',
}

interface GeoCompletionResult {
  description: string;
}

const YourDetails = ({ flags, navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const googleMapScript = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
  );

  const { borrower_state_or_province: borrowerState } = useSelector(preQualificationData);
  const { isManualFlowActivated } = useSelector((state: RootState) => state.manualFlow);

  const {
    init,
    ready,
    suggestions: { status, data },
    setValue: setAutocompleteValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      componentRestrictions: { country: 'us' },
      types: ['address'],
    },
    debounce: 300,
  });

  useEffect(() => {
    if (googleMapScript.loaded) {
      init();
    }
  }, [googleMapScript.loaded]);

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleGooglePlacesInput = async (event: ChangeEvent<HTMLInputElement>) => {
    setValue(YourDetailsVariable.AddressLine1, event.target.value);

    setAutocompleteValue(event.target.value);

    trigger(event.target.name as YourDetailsVariable);
  };

  const handleGooglePlacesSelect = (event: GeoCompletionResult) => async () => {
    const { description } = event;

    const [geocoderResult] = await getGeocode({ address: description });
    const address = getParsedAddress(geocoderResult.address_components);

    setValue(YourDetailsVariable.AddressLine1, address.address);
    setValue(YourDetailsVariable.ZipCode, address.zip);
    setValue(YourDetailsVariable.City, address.city);
    if (!borrowerState) {
      setValue(YourDetailsVariable.ArgyleState, address.state);
    }

    trigger(YourDetailsVariable.AddressLine1 as YourDetailsVariable);
    trigger(YourDetailsVariable.ZipCode as YourDetailsVariable);
    trigger(YourDetailsVariable.City as YourDetailsVariable);
    if (!borrowerState) {
      trigger(YourDetailsVariable.ArgyleState as YourDetailsVariable);
    }

    clearSuggestions();
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id: placeId,
        structured_formatting: { main_text: mainText, secondary_text: secondaryText },
      } = suggestion;

      return (
        <li key={placeId} onClick={handleGooglePlacesSelect(suggestion)} className={styles.dropdownOption}>
          {mainText} {secondaryText}
        </li>
      );
    });

  const dispatch = useDispatch();
  const yourDetails = useSelector(getYourAddressData);

  const { isFullAddressSent } = useSelector((state: RootState) => state.userPayrollData);

  const defaultValues = {
    [YourDetailsVariable.AddressLine1]: yourDetails[YourDetailsVariable.AddressLine1],
    [YourDetailsVariable.AddressLine2]: yourDetails[YourDetailsVariable.AddressLine2],
    [YourDetailsVariable.City]: yourDetails[YourDetailsVariable.City],
    [YourDetailsVariable.ZipCode]: yourDetails[YourDetailsVariable.ZipCode],
    [YourDetailsVariable.ArgyleState]: isManualFlowActivated
      ? (STATE_OPTIONS.find((state) => state.value === borrowerState)?.value as YourDetailsVariable)
      : yourDetails[YourDetailsVariable.ArgyleState],
  };

  const {
    register,
    watch,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const watcher = watch();

  useEffect(() => {
    register(YourDetailsVariable.AddressLine1, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.AddressLine1),
    });
    register(YourDetailsVariable.AddressLine2, {});
    register(YourDetailsVariable.City, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.City),
    });
    register(YourDetailsVariable.ZipCode, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.ZipCode),
    });
    register(YourDetailsVariable.ArgyleState, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.State),
    });
  }, [register, watcher]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as YourDetailsVariable, event.target.value.trim());
    trigger(event.target.name as YourDetailsVariable);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as YourDetailsVariable, event.target.value);
    trigger(event.target.name as YourDetailsVariable);
  };

  const handleContinue = async () => {
    dispatch(setYourDetailsData(watcher));
    if (!flags.showLocationScreen) {
      dispatch(setStateData(watcher[YourDetailsVariable.ArgyleState]));
    }
    handleNext();
  };

  return (
    <div className={styles.container}>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Where do you live?" subtitle="Let's customize your loan in a few steps.">
        <div className={styles.inputs}>
          <div className={clsx(styles.formInput, styles.dropdownContainer)} ref={ref}>
            <Input
              label={YourDetailsInputLabel.AddressLine1}
              placeholder="Address Line 1"
              errorMessage={errors[YourDetailsVariable.AddressLine1]?.message}
              name={YourDetailsVariable.AddressLine1}
              onBlur={onBlur}
              value={watcher[YourDetailsVariable.AddressLine1]}
              onChange={handleGooglePlacesInput}
              disabled={(!googleMapScript.loaded && !ready) || isFullAddressSent}
              data-neuro-label="address--line1"
            />
            {status === 'OK' && <ul className={styles.dropdownOptionsList}>{renderSuggestions()}</ul>}
          </div>
          <Input
            label={YourDetailsInputLabel.AddressLine2}
            placeholder="Address Line 2"
            className={styles.formInput}
            name={YourDetailsVariable.AddressLine2}
            onBlur={onBlur}
            onChange={onChange}
            value={watcher[YourDetailsVariable.AddressLine2]}
            disabled={(!googleMapScript.loaded && !ready) || isFullAddressSent}
            data-neuro-label="address--line2"
          />
          <Input
            label={YourDetailsInputLabel.City}
            placeholder="City"
            className={styles.formInput}
            errorMessage={errors[YourDetailsVariable.City]?.message}
            name={YourDetailsVariable.City}
            onBlur={onBlur}
            onChange={onChange}
            value={watcher[YourDetailsVariable.City]}
            disabled={isFullAddressSent}
            data-neuro-label="city"
          />
          <NumberInput
            label={YourDetailsInputLabel.ZipCode}
            placeholder="Zip code"
            errorMessage={errors[YourDetailsVariable.ZipCode]?.message}
            className={styles.formInput}
            name={YourDetailsVariable.ZipCode}
            onChange={onChange}
            value={watcher[YourDetailsVariable.ZipCode]}
            disabled={isFullAddressSent}
            data-neuro-label="zip"
          />
          <InputSelect
            label={YourDetailsInputLabel.State}
            options={STATE_OPTIONS}
            onChange={(option) => {
              setValue(YourDetailsVariable.ArgyleState, option.value);
              trigger(YourDetailsVariable.ArgyleState as YourDetailsVariable);
            }}
            value={watcher[YourDetailsVariable.ArgyleState]}
            placeholder="State"
            className={styles.formInput}
            disabled={Boolean(borrowerState) && (isManualFlowActivated || isFullAddressSent)}
            name={YourDetailsVariable.ArgyleState}
          />
        </div>
        <Button disabled={!isValid} onClick={handleContinue}>
          Continue
        </Button>
      </FormContainer>
    </div>
  );
};

export default withLDConsumer()(YourDetails);
