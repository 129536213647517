import React, { FC } from 'react';
import { Message } from 'react-hook-form';
import clsx from 'clsx';
import PhoneInput, { Props } from 'react-phone-number-input/input';
import InputError from 'components/InputError';

import styles from './PhoneNumberInput.module.scss';

interface PhoneNumberInputProps extends Props {
  value: string | undefined;
  label: string;
  errorMessage?: Message | undefined;
  className?: string;
  country: string;
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  onChange,
  value,
  label,
  errorMessage,
  className,
  placeholder,
  country,
  name,
  onBlur,
  maxLength,
}) => (
  <div className={className}>
    <div className={clsx(styles.inputContainer)}>
      {label && <p className={styles.label}>{label}</p>}
      <PhoneInput
        onChange={onChange}
        value={value}
        className={clsx(styles.input, !!errorMessage && styles.error)}
        onBlur={onBlur}
        placeholder={placeholder}
        country={country}
        name={name}
        maxLength={maxLength}
        data-neuro-label="phone"
      />
    </div>
    {!!errorMessage && <InputError errorMessage={errorMessage} />}
  </div>
);

export default PhoneNumberInput;
