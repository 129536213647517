import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { updateApplicationBankAccountData } from 'thunks';
import { setBankAccountData } from 'handlers/bankAccount';
import { RootState } from 'handlers';
import { BankAccountVariable } from 'enums/LoanFormVariables';
import { BankAccountInputLabel } from 'enums/BankAccountInputLabel';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormContainer from 'components/LoanForm/FormContainer';
import NumberInput from 'components/NumberInput';
import Button from 'components/Button';
import Input from 'components/Input';
import { getApplicationData } from 'selectors/getApplicationData';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './BankAccount.module.scss';

const ALPHABETICAL_WITH_SPACE_PATTERN = /^[a-zA-Z ]*$/;
const ROUTING_NUMBER_LENGTH_PATTERN = /^.{9}$/;
const ACCOUNT_NUMBER_MAX_LENGTH = 50;
const ROUTING_NUMBER_MAX_LENGTH = 9;

const BankAccount = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }
  const { isLoading } = useSelector((state: RootState) => state.bankAccount);

  const defaultValues = useSelector((state: RootState) => state.bankAccount.bankAccountData);

  const {
    register,
    watch,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const watcher = watch();

  useEffect(() => {
    register(BankAccountVariable.AccountHolder, {
      required: getMessageForRequiredFields(BankAccountInputLabel.AccountHolder),
      pattern: {
        message: getMessageForInvalidFields(BankAccountInputLabel.AccountHolder),
        value: ALPHABETICAL_WITH_SPACE_PATTERN,
      },
    });
    register(BankAccountVariable.BankName, {
      required: getMessageForRequiredFields(BankAccountInputLabel.BankName),
    });
    register(BankAccountVariable.RoutingNumber, {
      required: getMessageForRequiredFields(BankAccountInputLabel.RoutingNumber),
      pattern: {
        message: getMessageForInvalidFields(BankAccountInputLabel.RoutingNumber),
        value: ROUTING_NUMBER_LENGTH_PATTERN,
      },
    });
    register(BankAccountVariable.AccountNumber, {
      required: getMessageForRequiredFields(BankAccountInputLabel.AccountNumber),
    });
  }, [register, watcher]);

  const handleContinue = async () => {
    dispatch(setBankAccountData(watcher));
    await dispatchWithUnwrap(
      updateApplicationBankAccountData({
        applicationId: application!.id,
        bankAccountData: {
          [BankAccountVariable.AccountHolder]: watcher[BankAccountVariable.AccountHolder]!,
          [BankAccountVariable.BankName]: watcher[BankAccountVariable.BankName]!,
          [BankAccountVariable.RoutingNumber]: watcher[BankAccountVariable.RoutingNumber]!,
          [BankAccountVariable.AccountNumber]: watcher[BankAccountVariable.AccountNumber]!,
        },
      }),
    );

    handleNext();
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    trigger(event.target.name as BankAccountVariable);
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <FormContainer
          title="Bank account"
          subtitle="Enter your bank account information where you would like us to send the funds from the loan. The bank account may be used as a backup payment method"
        >
          <div className={styles.inputs}>
            <Input
              label={BankAccountInputLabel.AccountHolder}
              placeholder="Name of the account holder"
              errorMessage={errors[BankAccountVariable.AccountHolder]?.message}
              className={styles.formInput}
              name={BankAccountVariable.AccountHolder}
              onBlur={onBlur}
              onChange={(event) => {
                setValue(BankAccountVariable.AccountHolder, event.target.value);
                trigger(BankAccountVariable.AccountHolder);
              }}
              value={watcher[BankAccountVariable.AccountHolder]}
            />
            <Input
              label={BankAccountInputLabel.BankName}
              placeholder="Bank Name"
              errorMessage={errors[BankAccountVariable.BankName]?.message}
              className={styles.formInput}
              name={BankAccountVariable.BankName}
              onBlur={onBlur}
              onChange={(event) => {
                setValue(BankAccountVariable.BankName, event.target.value);
                trigger(BankAccountVariable.BankName);
              }}
              value={watcher[BankAccountVariable.BankName]}
            />
            <NumberInput
              label={BankAccountInputLabel.RoutingNumber}
              maxLength={ROUTING_NUMBER_MAX_LENGTH}
              placeholder="Routing Number"
              errorMessage={errors[BankAccountVariable.RoutingNumber]?.message}
              className={styles.formInput}
              name={BankAccountVariable.RoutingNumber}
              onBlur={onBlur}
              onChange={(event) => {
                setValue(BankAccountVariable.RoutingNumber, event.target.value);
                trigger(BankAccountVariable.RoutingNumber);
              }}
              value={watcher[BankAccountVariable.RoutingNumber]}
              allowLeadingZeros
              dataNeuroLabel="bank--routing"
            />
            <NumberInput
              label={BankAccountInputLabel.AccountNumber}
              maxLength={ACCOUNT_NUMBER_MAX_LENGTH}
              placeholder="Account Number"
              errorMessage={errors[BankAccountVariable.AccountNumber]?.message}
              className={styles.formInput}
              name={BankAccountVariable.AccountNumber}
              onBlur={onBlur}
              onChange={(event) => {
                setValue(BankAccountVariable.AccountNumber, event.target.value);
                trigger(BankAccountVariable.AccountNumber);
              }}
              value={watcher[BankAccountVariable.AccountNumber]}
              allowLeadingZeros
              dataNeuroLabel="bank--account"
            />
          </div>
          <Button onClick={handleContinue} disabled={!isValid} isLoading={isLoading}>
            Continue
          </Button>
        </FormContainer>
      </div>
    </>
  );
};

export default BankAccount;
