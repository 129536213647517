import React, { FC } from 'react';

import styles from './StateContainer.module.scss';

export interface ContainerProps {
  title: String;
  icon: JSX.Element;
}

const StateContainer: FC<ContainerProps> = ({ title, icon }) => (
  <div className={styles.stateContainer}>
    {React.cloneElement(icon, { className: styles.icon })}

    <div className={styles.title}>{title}</div>
  </div>
);

export default StateContainer;
