import React, { useEffect } from 'react';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import { RoutePath } from 'enums/Routes';
import { useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getApplicationData, getLoanOffer } from 'thunks';
import { LoanOfferResponse } from 'handlers/loanOffer';
import { ConversionType, trackConversion, trackConversionLead } from 'utils/analytics';
import { useNavigate, useNavigationType } from 'hooks/useNavigate';
import { getUtmTagsVariables } from 'utils/getUtmTags';
import { useQueryParams } from 'hooks/useQueryParam';
import StateContainer from 'components/StateContainer';
import { ErrorType } from 'components/Error/ErrorType';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';
import { getMethodAuthData } from 'selectors/methodAuth';
import { FlowComponentType } from 'routes/FlowRouter';
import { CurrentFlow } from 'enums/CurrentFlow';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';

import styles from './Analyzing.module.scss';

const Analyzing = ({ handleNext }: FlowComponentType): JSX.Element => {
  const params = useQueryParams();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const { entityId } = useSelector(getMethodAuthData);

  useEffect(() => {
    const generateOffer = async () => {
      let loanOfferResponse: LoanOfferResponse;

      try {
        loanOfferResponse = await dispatchWithUnwrap(
          getLoanOffer({
            application: {
              firstName,
              lastName,
              email,
              phoneNumber,
              entityId,
            },
            currentFlow: CurrentFlow.FinancialCheckup,
            utm: getUtmTagsVariables(params),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            updateCallback: (data) => {
              // TODO: show progress
            },
          }),
        );
      } catch (error) {
        navigate(RoutePath.Error, { state: { type: ErrorType.CantGenerateOffer } });
        return;
      }

      // only send to offer status if already applied
      if (loanOfferResponse.data.application_status === ApplicationStatusName.AlreadyApplied) {
        navigate(RoutePath.OfferStatus);
        return;
      }

      if (loanOfferResponse.data.borrower_id) {
        analytics.identify(loanOfferResponse.data.borrower_id);
        (window as any).nid('setUserId', loanOfferResponse.data.application_id);
      }

      const isQualified = loanOfferResponse.data.credit_passed;

      if (loanOfferResponse.data.application_id) {
        await dispatchWithUnwrap(getApplicationData(loanOfferResponse.data.application_id!));
      }
      if (isQualified) {
        trackConversionLead({
          email,
          firstName,
          lastName,
          phoneNumber,
        });
      }
      trackConversion(ConversionType.FinancialCheckupAccessed);

      handleNext();
    };

    if (navigationType === 'POP') {
      navigate(RoutePath.YourFinances);
      return;
    }
    generateOffer();
  }, []);

  return (
    <div className={styles.container}>
      <StateContainer icon={<LoadingSpinner />} title="We’re analyzing your finances..." />
    </div>
  );
};

export default Analyzing;
