import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import { AssociationBrandingName } from 'enums/AssociationBrandingName';
import { useLocation } from 'react-router-dom';
import { RoutePath } from 'enums/Routes';
import { ReactComponent as ChatIcon } from 'images/chat-icon.svg';
import LinkButton from 'components/LinkButton';
import { PhoneNumbers, PhoneNumbersLabels } from 'enums/PhoneNumbers';
import { getPaymentAccountData } from 'selectors/paymentAccount';
import useLayoutTheme from 'hooks/useLayoutTheme';

import defaultStyles from './Footer.module.scss';

const manualSetupDeductionRoutes = [
  RoutePath.SetupDeduction,
  RoutePath.SetupViaHr,
  RoutePath.SetupViaPayroll,
] as string[];

const Footer = () => {
  const location = useLocation();
  const { styles } = useLayoutTheme();
  const { association_branding: associationBranding } = useSelector((state: RootState) => state.preQualificationData);
  const { optedOut } = useSelector(getPaymentAccountData);

  const openSmsApp = () => {
    const smsAppUrl = `sms://${PhoneNumbers.Support}`;
    window.open(smsAppUrl);
  };
  return (
    <div className={defaultStyles.footer}>
      {location.pathname === RoutePath.Offer && (
        <span className={defaultStyles.disclaimerText}>
          Market rate loan data is based our compilation of rates from specific aggregator websites, on specific dates.
          Only considers specific lenders who share pricing on these sites. Please do your own shopping to confirm rates
          you may qualify for outside of Plannery.
        </span>
      )}
      <div className={defaultStyles.contactButton}>
        <ChatIcon className={clsx(defaultStyles.icon, styles.chatIcon)} />
        <LinkButton className={styles.needHelp} onClick={openSmsApp} isLoading={false}>
          Need Help? Contact us at {PhoneNumbersLabels.Support}
        </LinkButton>
      </div>
      {manualSetupDeductionRoutes.includes(location.pathname) && !optedOut && (
        <div className={defaultStyles.disclaimerText}>Banking services are provided by Emprise Bank, Member FDIC.</div>
      )}
      {associationBranding === AssociationBrandingName.Fpma && (
        <span className={defaultStyles.podiatristsText}>Plannery ❤️ podiatrists</span>
      )}
      <span className={clsx(defaultStyles.copyright, styles.copyright)}>
        &#169; {new Date().getFullYear()} Plannery. All Rights Reserved
      </span>
    </div>
  );
};

export default Footer;
