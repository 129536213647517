import Api, { FetchMethodType } from './Api';

export interface CreateElementTokenResponse {
  entityId: string;
  elementToken: string;
}

export interface CreateElementTokenData {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
}

export interface MethodApi {
  createElementToken(credentials: CreateElementTokenData): Promise<CreateElementTokenResponse>;
}

export default class MethodApiRest extends Api implements MethodApi {
  public async createElementToken(data: CreateElementTokenData) {
    const response = await this.fetch<CreateElementTokenResponse>('/method/elementToken', {
      method: FetchMethodType.POST,
      body: data,
    });

    return response;
  }
}
