import Api, { FetchMethodType } from './Api';

export interface DataForUploadingDocuments {
  applicationId: string;
  files: File[];
}

export interface ApplicationDocumentsApi {
  uploadDocuments(data: DataForUploadingDocuments): Promise<void>;
}

export default class ApplicationDocumentsRestApi extends Api implements ApplicationDocumentsApi {
  public async uploadDocuments(data: DataForUploadingDocuments) {
    const formData = new FormData();

    data.files.map((file) => formData.append('files', file));

    formData.append('applicationId', data.applicationId as string);

    await this.fetch<void>(`/documents/upload`, {
      method: FetchMethodType.POST,
      body: formData,
      resetDefaultHeaders: true,
    });
  }
}
