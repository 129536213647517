import React, { FC, useState, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';
import useDropdown from 'hooks/useDropdown';
import { ReactComponent as ChevronIcon } from 'images/chevron.svg';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { HardOfferData, Tradeline } from 'handlers/applicationData';
import Loader from 'components/Loader';
import LinkButton from 'components/LinkButton';

import { updateSelectedTradelines } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import { failureNotification } from 'notifications';

import { AVAILABLE_LOAN_AMOUNT } from 'components/HardOffer/components/PayOffPlan/PayOffPlan';
import { RootState } from 'handlers';
import { useSelector } from 'react-redux';

import { SelectedTradeline } from 'api/ApplicationDataApi';

import ConsolidationTable from './ConsolidationTable';
import OtherDebts from './OtherDebts';

import styles from './DebtConsolidationDropdown.module.scss';

interface DebtConsolidationProps {
  data: HardOfferData;
  setHardOffer: (hardOffer: HardOfferData) => void;
}

export enum MinimumAmountsPerState {
  GA = 3000,
  FL = 2000,
  NC = 2000,
}

export const getStateMinimumAmount = (borrower_state: string) =>
  MinimumAmountsPerState[borrower_state as keyof typeof MinimumAmountsPerState];

const DebtConsolidationDropdown: FC<DebtConsolidationProps> = ({ data, setHardOffer }): JSX.Element => {
  const subDropdown = useDropdown(); // Other loans
  const { isOpen, handleToggleDropdown, contentRef } = useDropdown();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const [editModeIsOn, setEditModeIsOn] = useState(false);

  const { isLoading, application } = useSelector((state: RootState) => state.applicationData);

  const [selectedTradelines, setSelectedTradelines] = useState<Record<string, SelectedTradeline>>({});

  const containerRef = useRef<HTMLDivElement>(null);

  const { payOff, planneryLoan, keepIt, cantConsolidate } = data;
  const tradelinesConsolidated: Tradeline[] = [...payOff, ...keepIt].filter((t) => t.selectedForConsolidation);
  const [currentLoanAmount, setCurrentLoanAmount] = useState<number>(planneryLoan.amount);
  const [currentNumberOfAccounts, setCurrentNumberOfAccounts] = useState<number>(tradelinesConsolidated.length);

  const validLoanAmount =
    currentLoanAmount <= AVAILABLE_LOAN_AMOUNT &&
    currentLoanAmount >= getStateMinimumAmount(application?.borrowerStateOrProvince as string);

  useEffect(() => {
    if (currentLoanAmount > AVAILABLE_LOAN_AMOUNT) {
      failureNotification(
        `You have selected the max amount of ${formatMonetaryAmount(
          AVAILABLE_LOAN_AMOUNT,
        )}. Deselect an account first.`,
      );
      return;
    }

    const stateMinimum = getStateMinimumAmount(application?.borrowerStateOrProvince as string);
    if (currentLoanAmount < stateMinimum) {
      failureNotification(
        `You have selected under the minimum amount of ${formatMonetaryAmount(
          stateMinimum,
        )}. Please select more accounts.`,
      );
    }
  }, [validLoanAmount]);

  const handleToggle = () => {
    // Close the inner dropdown if the main one is closed to avoid wrong height
    if (isOpen && subDropdown.isOpen) {
      subDropdown.handleToggleDropdown();
    }
    handleToggleDropdown();
    if (!isOpen) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSelectedTradelinesOnClick = useCallback(async () => {
    if (!Object.values(selectedTradelines).length) {
      failureNotification('Please select or unselect at least one account.');
      return;
    }

    const updatedApplication = await dispatchWithUnwrap(
      updateSelectedTradelines({
        applicationId: application!.id,
        tradelinesToUpdate: Object.values(selectedTradelines),
      }),
    );
    setSelectedTradelines({});
    setHardOffer(updatedApplication.application.hardOffer as HardOfferData);
    setEditModeIsOn(false);
  }, [selectedTradelines, planneryLoan, application]);

  const handleCancelEdit = () => {
    setEditModeIsOn(false);
    setCurrentLoanAmount(planneryLoan.amount);
    setSelectedTradelines({});
  };

  return (
    <div
      ref={containerRef}
      className={clsx(styles.container, {
        [styles.mobileOpen]: isOpen,
      })}
    >
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <div className={styles.title}>1. Consolidate debt</div>
          <div className={styles.consolidationAmount}>
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <Loader color="#9d86f9" size={25} />
              </div>
            ) : (
              <>
                <span
                  className={clsx({
                    [styles.amountError]: !validLoanAmount,
                  })}
                >
                  {formatMonetaryAmount(currentLoanAmount as number)}
                </span>{' '}
                / {currentNumberOfAccounts} account{currentNumberOfAccounts !== 1 && 's'}
              </>
            )}
          </div>
        </div>
        <div
          onClick={handleToggle}
          className={clsx(styles.dropdownButton, {
            [styles.isToggled]: isOpen,
          })}
        >
          <ChevronIcon />
        </div>
      </div>

      <div
        ref={contentRef}
        className={clsx(styles.content, {
          [styles.dropdownOpen]: isOpen,
        })}
      >
        <div className={styles.recommended}>Recommended</div>
        <div className={styles.subtitle}>
          Save by consolidating these debts <br /> with your Plannery loan:
        </div>
        {payOff.every((tradeline) => tradeline.id) && keepIt.every((tradeline) => tradeline.id) && editModeIsOn ? (
          <>
            <LinkButton isLoading={isLoading} onClick={handleCancelEdit}>
              Cancel
            </LinkButton>
            <LinkButton onClick={handleSelectedTradelinesOnClick} isLoading={isLoading} disabled={!validLoanAmount}>
              Submit changes
            </LinkButton>
          </>
        ) : (
          <LinkButton onClick={() => setEditModeIsOn(true)} isLoading={isLoading}>
            Edit selected debts
          </LinkButton>
        )}
        <ConsolidationTable
          tradeLines={payOff}
          selectedTradelines={selectedTradelines}
          setSelectedTradelines={setSelectedTradelines}
          editModeIsOn={editModeIsOn}
          setCurrentLoanAmount={setCurrentLoanAmount}
          setCurrentNumberOfAccounts={setCurrentNumberOfAccounts}
        />

        <div className={styles.hint}>
          <div className={styles.hintText}>* Estimate is based on your credit report.</div>
          <div className={styles.hintText}>The market average is used when your exact data is unavailable.</div>
        </div>

        <OtherDebts
          dropdown={subDropdown}
          keepIt={keepIt}
          cantConsolidate={cantConsolidate}
          selectedTradelines={selectedTradelines}
          setSelectedTradelines={setSelectedTradelines}
          editModeIsOn={editModeIsOn}
          setCurrentLoanAmount={setCurrentLoanAmount}
          setCurrentNumberOfAccounts={setCurrentNumberOfAccounts}
        />
      </div>
    </div>
  );
};
export default DebtConsolidationDropdown;
