import React, { FC } from 'react';
import clsx from 'clsx';

import CommonQuestions from 'components/CommonQuestions';
import Header from 'components/Header';
import Footer from 'components/Footer';

import ConfettiMobil from 'images/сonfetti-mobil.svg';
import ConfettiDesktop from 'images/сonfetti-desktop.svg';
import useLayoutTheme from 'hooks/useLayoutTheme';
import { ReactComponent as Hexagons } from 'images/background-hexagons.svg';
import { ReactComponent as HexagonsMiddle } from 'images/background-hexagons-middle.svg';

import defaultStyles from './MainLayout.module.scss';

interface MainLayoutProps {
  confettiBackground?: boolean;
}

const MainLayout: FC<MainLayoutProps> = ({ children, confettiBackground }) => {
  const { styles, shouldApplyTheme } = useLayoutTheme();

  return (
    <div className={clsx(defaultStyles.layoutContainer, styles.layoutContainer)}>
      <Header />
      <div className={clsx(defaultStyles.layoutContent, styles.layoutContent)}>
        {confettiBackground && (
          <picture>
            <source media="(max-width: 1023px)" srcSet={ConfettiMobil} />
            <source media="(min-width: 1024px)" srcSet={ConfettiDesktop} />
            <img src={ConfettiDesktop} alt="Background" className={defaultStyles.confetti} />
          </picture>
        )}
        {children}
        <CommonQuestions />
      </div>
      <Footer />
      {shouldApplyTheme && (
        <>
          <Hexagons className={clsx(defaultStyles.hexagonTop, styles.hexagons)} />
          <HexagonsMiddle className={clsx(defaultStyles.hexagonMiddle, styles.hexagons)} />
          <Hexagons className={clsx(defaultStyles.hexagonBottom, styles.hexagons)} />
        </>
      )}
    </div>
  );
};

export default MainLayout;
