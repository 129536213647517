import React, { FC } from 'react';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import { copyToClipboard } from 'utils/copyToClipboard';

interface ShareButtonProps {
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  type?: ButtonType;
  id?: string;
  url?: string;
}

const ShareButton: FC<ShareButtonProps> = ({
  children,
  onClick,
  className,
  isLoading,
  disabled,
  type = ButtonType.Primary,
  id,
  url,
}) => {
  const onShare = async () => {
    url && (await copyToClipboard(url, 'Link copied to clipboard!'));
    onClick && onClick();
  };

  return (
    <Button id={id} type={type} className={className} isLoading={isLoading} disabled={disabled} onClick={onShare}>
      {children}
    </Button>
  );
};

export default ShareButton;
