import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YourIncomeVariable } from 'enums/LoanFormVariables';

export enum PayFrequencyOptionLabel {
  Weekly = 'Weekly',
  BiWeekly = 'Bi-weekly',
  SemiMonthly = 'Semi-monthly',
  Monthly = 'Monthly',
  Other = 'Other',
}

export enum PayFrequencyOptionValue {
  Weekly = 'weekly', // every week
  BiWeekly = 'biweekly', // every other week
  SemiMonthly = 'semimonthly', // twice a month
  Monthly = 'monthly', // every month
  Other = 'other', // Custom frequency
}

export interface YourIncomeResponse {
  employmentIncome: Record<string, string>[];
}

export interface YourIncomeData {
  [YourIncomeVariable.TotalAnnualIncome]: string;
  [YourIncomeVariable.PayFrequency]: string;
}

export const initialState: YourIncomeData = {
  [YourIncomeVariable.TotalAnnualIncome]: '',
  [YourIncomeVariable.PayFrequency]: '',
};

const yourTotalIncomeReducer = createSlice({
  name: 'yourIncome',
  initialState,
  reducers: {
    setYourIncomeData: (state: YourIncomeData, { payload }: PayloadAction<YourIncomeData>) => {
      state[YourIncomeVariable.TotalAnnualIncome] = payload[YourIncomeVariable.TotalAnnualIncome];
      state[YourIncomeVariable.PayFrequency] = payload[YourIncomeVariable.PayFrequency];
    },
  },
});

export const { setYourIncomeData } = yourTotalIncomeReducer.actions;

export default yourTotalIncomeReducer.reducer;
