import React, { useEffect, useState } from 'react';
import FormNavigation from 'components/FormNavigation';
import Button from 'components/Button';
import { ReactComponent as MinusIcon } from 'images/minus-circle-icon.svg';
import { ReactComponent as PlusIcon } from 'images/plus-circle-icon.svg';
import { ButtonType } from 'components/Button/Button';
import { LoanTermsVariables } from 'enums/loanTermsVariables';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'handlers';
import { PreQualificationResponse } from 'handlers/preQualificationResult';
import { LoanAmountRange, setLoanAmountRangeData } from 'handlers/preQualificationData';
import { getCreditScoreRangeData } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { setLoanTermsData } from 'handlers/loanTerms';
import { useLocation } from 'react-router-dom';
import { FlowComponentType } from 'routes/FlowRouter';
import { setSeenOffer } from 'handlers/userHistory';
import Loader from 'components/Loader';

import OfferCard from './components/OfferCard';
import { CardState, OfferType } from './components/OfferCard/OfferCard';

import styles from './Offer.module.scss';

const LoanAmounts = [2000, 5000, 10000, 15000, 25000, 50000];

const LoanAmountRanges = Object.values(LoanAmountRange) as LoanAmountRange[];

const Offer = ({ flags, navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const { showCreditScoreScreen } = flags;

  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const location = useLocation();

  const {
    [LoanTermsVariables.InterestRate]: InterestRate,
    [LoanTermsVariables.MarketAnnualPercentRate]: MarketAPR,
    [LoanTermsVariables.LoanTerm]: LoanTerm,
    [LoanTermsVariables.MinLoanAmount]: MinLoanAmount,
    [LoanTermsVariables.MaxLoanAmount]: MaxLoanAmount,
    [LoanTermsVariables.MinMonthlyPayment]: MinMonthlyPayment,
    [LoanTermsVariables.MaxMonthlyPayment]: MaxMonthlyPayment,
    [LoanTermsVariables.MarketMaxMonthlyPayment]: MarketMaxMonthlyPayment,
    [LoanTermsVariables.LoanTotalSavings]: LoanTotalSavings,
  } = useSelector((state: RootState) => state.loanTermsData);

  const {
    borrower_state_or_province: borrowerState,
    credit_score_range: creditScoreRange,
    loan_amount_range: loanAmountRange,
    loan_purpose: loanPurpose,
    loan_term_in_months: loanTermInMonths,
  } = useSelector((state: RootState) => state.preQualificationData);

  const { isLoading } = useSelector((state: RootState) => state.preQualificationResult);

  const [loanAmountIndex, setLoanAmountIndex] = useState(
    LoanAmountRanges.findIndex((value) => value === loanAmountRange),
  );

  const refreshData = async (newLoanAmountRange: LoanAmountRange) => {
    // Save the credit score range data
    const result: PreQualificationResponse = await dispatchWithUnwrap(
      getCreditScoreRangeData({
        credit_score_range: creditScoreRange,
        loan_amount_range: newLoanAmountRange,
        borrower_state_or_province: borrowerState,
        loan_term_in_months: loanTermInMonths,
      }),
    );
    dispatch(setLoanTermsData(result.output_variables!));

    // Updates the loan amount range
    dispatch(setLoanAmountRangeData({ loan_amount_range: newLoanAmountRange }));
  };

  const onLoanAmountChange = async (increase?: boolean) => {
    const newIndex = increase ? loanAmountIndex + 1 : loanAmountIndex - 1;
    setLoanAmountIndex(newIndex);
    const newLoanAmountRange = LoanAmountRanges[newIndex];

    await refreshData(newLoanAmountRange);

    analytics.track('Loan Amount Range Updated on Offer Page', { newLoanAmountRange });
  };

  // @ts-ignore
  const hasSeenDecline = location.state?.hasSeenDecline;

  const offerAnalyticsVariables = {
    hasSeenDecline,
    interestRate: InterestRate,
    loanTerm: LoanTerm,
    minimumLoanAmount: MinLoanAmount,
    maximumLoanAmount: MaxLoanAmount,
    minimumMonthlyPayment: MinMonthlyPayment,
    maximumMonthlyPayment: MaxMonthlyPayment,
    borrowerState,
    creditScoreRange,
    loanAmountRange,
    loanPurpose,
  };

  useEffect(() => {
    analytics.track('Offer Available', offerAnalyticsVariables);
    dispatch(setSeenOffer());

    if (!InterestRate) {
      refreshData(loanAmountRange!);
    }
  }, []);

  const handleSubmit = async () => {
    analytics.track('Initial Offer Accepted', offerAnalyticsVariables);
    if (!hasSeenDecline) {
      // Used for conversion tracking.
      analytics.track('Initial Offer Accepted without Decline');
    }

    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        {showCreditScoreScreen && (
          <span className={styles.title}>See what you save with a Healthcare Worker loan.</span>
        )}
        <span className={styles.loanAmountPickerLabel}>
          {!showCreditScoreScreen && (isLoading || (LoanTotalSavings && LoanTotalSavings > 0))
            ? 'If you borrow'
            : `Consolidate${loanAmountIndex > 0 && ' as much as'}`}
        </span>
        <div className={styles.loanAmount}>
          <Button
            type={ButtonType.Secondary}
            className={styles.iconContainer}
            disabled={loanAmountIndex <= 0 || isLoading}
            onClick={() => onLoanAmountChange(false)}
          >
            <MinusIcon className={loanAmountIndex <= 0 || isLoading ? styles.disabledIcon : ''} />
          </Button>
          <h1>${LoanAmounts[loanAmountIndex]?.toLocaleString()}</h1>
          <Button
            type={ButtonType.Secondary}
            className={styles.iconContainer}
            disabled={loanAmountIndex >= LoanAmounts.length - 1 || isLoading}
            onClick={() => onLoanAmountChange(true)}
          >
            <PlusIcon className={loanAmountIndex >= LoanAmounts.length - 1 || isLoading ? styles.disabledIcon : ''} />
          </Button>
        </div>
        {isLoading ? (
          <Loader color="#9d86f9" size={68} />
        ) : (
          !showCreditScoreScreen &&
          LoanTotalSavings &&
          LoanTotalSavings > 0 && (
            <span className={styles.title}>
              You can{' '}
              <span className={styles.savings}>
                {' '}
                save{' '}
                {LoanTotalSavings!.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0,
                })}
                .
              </span>
            </span>
          )
        )}

        <div className={styles.offersContainer}>
          <OfferCard
            offerType={LoanTotalSavings! >= 0 ? OfferType.Savings : OfferType.PriceMatched}
            state={CardState.Normal}
            title="Plannery Health Worker Loan"
            monthAmount={LoanTotalSavings! >= 0 ? MaxMonthlyPayment! : MarketMaxMonthlyPayment!}
            months={loanTermInMonths!}
            apr={parseFloat((InterestRate! * 100).toFixed(2))}
            savings={LoanTotalSavings! >= 0 ? LoanTotalSavings : undefined}
            loading={isLoading}
            aprMatched={LoanTotalSavings! < 0 ? parseFloat((MarketAPR! * 100).toFixed(2)) : undefined}
            showSavings={showCreditScoreScreen}
            showTerm={showCreditScoreScreen}
          />
          <OfferCard
            offerType={OfferType.Market}
            state={CardState.Normal}
            title="Average Personal Loan"
            monthAmount={MarketMaxMonthlyPayment!}
            months={loanTermInMonths!}
            apr={parseFloat((MarketAPR! * 100).toFixed(2))}
            loading={isLoading}
            showSavings={showCreditScoreScreen}
            showTerm={showCreditScoreScreen}
          />
        </div>
        {!showCreditScoreScreen && (
          <span className={styles.footnote}>
            Based on a {loanTermInMonths} month loan and a 580 credit score. Next, get your savings!
          </span>
        )}
        <Button className={styles.applyButton} onClick={handleSubmit}>
          {showCreditScoreScreen ? 'Apply' : 'Get My Savings'}
        </Button>
      </div>
    </>
  );
};

export default Offer;
