import React, { useEffect } from 'react';

import Button from 'components/Button';

import { useSelector } from 'react-redux';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { RoutePath } from 'enums/Routes';
import { getApplicationData } from 'selectors/getApplicationData';
import { HardOfferData } from 'handlers/applicationData';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { useNavigate } from 'hooks/useNavigate';

import LoanSummary from './components/PayOffPlan/components/LoanSummary';

import styles from './HardOfferSummary.module.scss';

const HardOffer = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const navigate = useNavigate();
  const application = useSelector(getApplicationData).application!;

  const hardOffer = application.hardOffer as HardOfferData;

  const { loanAmount, moneySaved, monthsSaved } = hardOffer!.offerSummary;

  const { amount: consolidatedAmount } = hardOffer.payOffTotal;

  useEffect(() => {
    if (consolidatedAmount !== application.loanAmount) {
      navigate(RoutePath.ConfirmLoan);
    }
  }, []);

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <div className={styles.title}>We did the math and maximized your savings.</div>

        <div className={styles.subtitle}>
          Based on your debt profile, we can consolidate{' '}
          <span className={styles.highlighted}>{formatMonetaryAmount(loanAmount)}</span> of your current debt and save
          you time and money.
        </div>

        <LoanSummary loanAmount={loanAmount} moneySaved={moneySaved} monthsSaved={monthsSaved} />

        <div className={styles.detailsText}>Next, we'll show you the details.</div>

        <Button onClick={handleNext} className={styles.nextButton}>
          Next
        </Button>
      </div>
    </>
  );
};

export default HardOffer;
