import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import rootReducer from 'handlers';
import saveApplicationStepMiddleware from 'middlewares/saveApplicationStep';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';
import { getLocalStorageItem } from 'utils/getLocalStorageItem';

export const history = createBrowserHistory();

const urlSearchParams = new URLSearchParams(window.location.search);

const initialState = urlSearchParams.get('resume-process')
  ? undefined
  : getLocalStorageItem(LocalStorageKeyName.ApplicationState);

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), saveApplicationStepMiddleware],
  preloadedState: initialState || undefined,
});

export type AppDispatch = typeof store.dispatch;

export default store;
