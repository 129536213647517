import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { getLoanOffer } from 'thunks';

interface LoanOffer {
  isLoading: boolean;
  isCompleted: boolean;
  isError: boolean;
  response: LoanOfferResponse;
}
export interface LoanOfferData {
  application_status?: ApplicationStatusName | undefined;
  application_id?: string | undefined;
  borrower_id?: string | undefined;
  credit_passed?: boolean | undefined;
}
export interface LoanOfferResponse {
  passed: boolean | undefined;
  data: LoanOfferData;
}

const initialState: LoanOffer = {
  response: {
    passed: undefined,
    data: {},
  },
  isLoading: false,
  isCompleted: false,
  isError: false,
};

const loanOffer = createSlice({
  name: 'loanOffer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLoanOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLoanOffer.fulfilled, (state, { payload }: PayloadAction<LoanOfferResponse>) => {
      state.isLoading = false;
      state.isCompleted = true;
      state.response = payload;
    });
    builder.addCase(getLoanOffer.rejected, (state) => {
      state.isLoading = false;
      state.isCompleted = true;
      state.isError = true;
    });
  },
});

export default loanOffer.reducer;
