import React, { FC } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { Message } from 'react-hook-form';
import clsx from 'clsx';

import InputError from 'components/InputError';

import styles from './NumberInput.module.scss';

const DECIMAL_PLACES_AMOUNT = 0;

interface NumberInputProps extends NumberFormatProps {
  label: string;
  errorMessage?: Message | undefined;
  decimalPlacesAmount?: number;
  disabled?: boolean;
  value?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  format?: string;
  dataNeuroLabel?: string;
  hint?: string;
}

const NumberInput: FC<NumberInputProps> = ({
  prefix,
  label,
  onChange,
  placeholder,
  className,
  allowNegative,
  allowLeadingZeros,
  thousandSeparator,
  suffix,
  errorMessage,
  onBlur,
  name,
  maxLength,
  max,
  disabled,
  value,
  isAllowed,
  format,
  decimalPlacesAmount = DECIMAL_PLACES_AMOUNT,
  dataNeuroLabel,
  hint,
}) => (
  <div className={className}>
    <div className={clsx(styles.inputContainer)}>
      {label && <p className={styles.label}>{label}</p>}
      <NumberFormat
        className={clsx(styles.input, !!errorMessage && styles.error)}
        prefix={prefix}
        suffix={suffix}
        onChange={onChange}
        placeholder={placeholder}
        allowNegative={allowNegative}
        allowLeadingZeros={allowLeadingZeros}
        thousandSeparator={thousandSeparator}
        decimalScale={decimalPlacesAmount}
        onBlur={onBlur}
        name={name}
        maxLength={maxLength}
        max={max}
        disabled={disabled}
        value={value}
        isAllowed={isAllowed}
        format={format}
        data-neuro-label={dataNeuroLabel}
      />
    </div>
    {!!hint && <div className={styles.hint}>{hint}</div>}
    {!!errorMessage && <InputError errorMessage={errorMessage} />}
  </div>
);

export default NumberInput;
