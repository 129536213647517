import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useQueryParams } from 'hooks/useQueryParam';
import { getCreditReportData } from 'selectors/creditReport';
import { getReferralsData } from 'selectors/referrals';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import { CreditReportVariable } from 'enums/LoanFormVariables';
import { DataForGettingLoanOffer } from 'api/LoanOfferApi';
import { RootState } from 'handlers';
import FormContainer from 'components/LoanForm/FormContainer';
import NumberInput from 'components/NumberInput';
import Button from 'components/Button';
import FormNavigation from 'components/FormNavigation';
import { getUtmTagsVariables } from 'utils/getUtmTags';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { getYourEmployer } from 'selectors/yourEmployer';
import { NON_DIGITS_PATTERN } from 'components/Input/validation';

import styles from './CreditReport.module.scss';

const SOCIAL_SECURITY_LENGTH_PATTERN = /^.{9}$/;

enum CreditReportInputLabel {
  SocialSecurityNumber = 'Social Security Number',
}

const CreditReport = ({ navigationInfo }: any): JSX.Element => {
  const params = useQueryParams();
  const navigate = useNavigate();
  const defaultValues = useSelector(getCreditReportData);
  const { isLoading } = useSelector((state: RootState) => state.loanOffer);
  const { isManualFlowActivated } = useSelector((state: RootState) => state.manualFlow);

  const { goal } = useSelector((state: RootState) => state.yourGoal);

  const { last_name: lastName, first_name: firstName } = useSelector((state: RootState) => state.yourName);

  const { birth_date: birthDate } = useSelector((state: RootState) => state.yourBirthDate);

  const { email, phone_number: phoneNumber } = useSelector((state: RootState) => state.yourContact);

  const {
    city,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    zip_code: zipCode,
    argyle_state: argyleState,
  } = useSelector((state: RootState) => state.yourAddress);

  const {
    loan_amount_range: loanAmount,
    borrower_state_or_province: borrowerState,
    loan_purpose: loanPurpose,
    credit_score_range: creditScoreRange,
  } = useSelector((state: RootState) => state.preQualificationData);

  const { total_annual_income: totalAnnualIncome, pay_frequency: payFrequency } = useSelector(
    (state: RootState) => state.yourTotalIncome,
  );

  const { employment: payrollApiEmployment, employersLinkItemIds } = useSelector(
    (state: RootState) => state.employmentData,
  );

  const { employer_1: employer1, employer_2: employer2 } = useSelector(getYourEmployer);

  const { referred_by: referredBy } = useSelector(getReferralsData);

  const { isSsn, isFullAddressSent } = useSelector((state: RootState) => state.userPayrollData);
  const {
    register,
    watch,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const watcher = watch();

  useEffect(() => {
    register(CreditReportVariable.SocialSecurityNumber, {
      required: getMessageForRequiredFields(CreditReportInputLabel.SocialSecurityNumber),
      pattern: {
        message: getMessageForInvalidFields(CreditReportInputLabel.SocialSecurityNumber),
        value: SOCIAL_SECURITY_LENGTH_PATTERN,
      },
    });
  }, [register, watcher]);

  const handleContinue = async () => {
    const payrollProviders = [employer1.provider, employer2.provider];
    let employment;

    if (isManualFlowActivated) {
      employment = [
        {
          borrower_employer_name: employer1.data.employer_name,
          annual_income: employer1.data.annual_income
            ? Number(employer1.data.annual_income.replace(NON_DIGITS_PATTERN, ''))
            : undefined,
          pay_frequency: payFrequency,
          payroll_provider: 'Manual',
        },
        {
          borrower_employer_name: employer2.data.employer_name,
          annual_income: employer2.data.annual_income
            ? Number(employer2.data.annual_income.replace(NON_DIGITS_PATTERN, ''))
            : undefined,
          pay_frequency: employer2.data.pay_frequency,
          payroll_provider: 'Manual',
        },
      ];
    } else {
      employment = payrollApiEmployment.map((employer: any, idx: number) => {
        const em = { ...employer };
        em.payroll_provider = payrollProviders[idx];
        return em;
      });
    }

    const dataForGettingLoanOffer: DataForGettingLoanOffer = {
      application: {
        firstName,
        lastName,
        streetAddress: addressLine2 ? `${addressLine1} ${addressLine2}` : addressLine1,
        city,
        state: borrowerState!,
        zipCode,
        personalIdNumber: watcher[CreditReportVariable.SocialSecurityNumber],
        dateOfBirth: birthDate,
        argyleState,
        loanPurpose: loanPurpose!,
        loanAmountRange: loanAmount!,
        creditScoreRange: creditScoreRange!,
        totalAnnualIncome: totalAnnualIncome ? Number(totalAnnualIncome.replace(NON_DIGITS_PATTERN, '')) : 0,
        email,
        phoneNumber,
        isFullAddressSent,
        loanGoal: goal,
        referredBy,
        resumeLink: `${window.location.href}`,
      },
      employment,
      employersLinkItemIds,
      utm: getUtmTagsVariables(params),
    };

    navigate(RoutePath.CreatingOffer, {
      state: {
        dataForGettingLoanOffer,
        watcher,
      },
    });
  };

  return (
    <div className={styles.container}>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="What's your Social Security Number?" subtitle="One last step to calculate your savings.">
        <div className={styles.inputs}>
          <NumberInput
            label={CreditReportInputLabel.SocialSecurityNumber}
            className={styles.formInput}
            placeholder="XXX-XX-XXXX"
            format="###-##-####"
            name={CreditReportVariable.SocialSecurityNumber}
            errorMessage={errors[CreditReportVariable.SocialSecurityNumber]?.message}
            onChange={(event) => {
              setValue(CreditReportVariable.SocialSecurityNumber, event.target.value.replace(NON_DIGITS_PATTERN, ''));
              trigger(event.target.name as CreditReportVariable);
            }}
            value={watcher[CreditReportVariable.SocialSecurityNumber]}
            disabled={isSsn}
            dataNeuroLabel="ssn"
          />
        </div>
        <p className={styles.consent}>
          By clicking Agree and Calculate Savings, I hereby consent to the{' '}
          <a href="https://www.planneryapp.com/esign-agreement" target="_blank" rel="noreferrer">
            E-Sign Agreement
          </a>{' '}
          and{' '}
          <a href="https://www.planneryapp.com/terms-of-service" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
          , and I am providing written consent under the FCRA for Plannery to obtain consumer report information from my
          credit profile.
        </p>
        <Button disabled={!isValid} onClick={handleContinue} isLoading={isLoading}>
          Agree and calculate savings
        </Button>
      </FormContainer>
    </div>
  );
};

export default CreditReport;
