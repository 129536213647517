import React, { Dispatch, SetStateAction } from 'react';
import useDropdown from 'hooks/useDropdown';
import clsx from 'clsx';

import { ReactComponent as PlayIcon } from 'images/play-button.svg';
import { Tradeline } from 'handlers/applicationData';

import { SelectedTradeline } from 'api/ApplicationDataApi';

import ConsolidationTable from './ConsolidationTable';

import styles from './OtherDebts.module.scss';

interface OtherDebtsProps {
  dropdown: ReturnType<typeof useDropdown>;
  keepIt: Tradeline[];
  cantConsolidate: Tradeline[];
  editModeIsOn: boolean;
  selectedTradelines: Record<string, SelectedTradeline>;
  setSelectedTradelines: (selectedTradelines: Record<string, SelectedTradeline>) => void;
  setCurrentLoanAmount: Dispatch<SetStateAction<number>>;
  setCurrentNumberOfAccounts: Dispatch<SetStateAction<number>>;
}

const OtherDebts = ({
  dropdown,
  keepIt,
  cantConsolidate,
  selectedTradelines,
  setSelectedTradelines,
  editModeIsOn,
  setCurrentLoanAmount,
  setCurrentNumberOfAccounts,
}: OtherDebtsProps) => {
  const { isOpen, handleToggleDropdown, height, contentRef } = dropdown;

  return (
    <div className={styles.container}>
      <div onClick={handleToggleDropdown} className={styles.header}>
        <PlayIcon
          className={clsx(styles.icon, {
            [styles.open]: isOpen,
          })}
        />
        <div className={styles.title}>Other debts</div>
      </div>

      <div
        ref={contentRef}
        style={{
          height: `${height}px`,
        }}
        className={clsx(styles.content, {
          [styles.toggleDropdown]: isOpen,
        })}
      >
        <div className={styles.subtitle}>You won’t get any savings by consolidating these debts.</div>

        <ConsolidationTable
          tradeLines={keepIt}
          selectedTradelines={selectedTradelines}
          setSelectedTradelines={setSelectedTradelines}
          editModeIsOn={editModeIsOn}
          setCurrentLoanAmount={setCurrentLoanAmount}
          setCurrentNumberOfAccounts={setCurrentNumberOfAccounts}
        />

        <div className={styles.notRecommended}>
          <div className={styles.subtitle}>
            We don't recommend that you consolidate car, home, or school debt with this loan.
          </div>
          <div className={styles.list}>
            {cantConsolidate.map((t, index) => (
              <div key={`${t.firm?.trim()}-${index}`} className={styles.row}>
                {t.firm} {t.accountMask}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherDebts;
