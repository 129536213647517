import React, { useEffect } from 'react';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import alloy from '@alloyidentity/web-sdk';
import Button from 'components/Button';
import { verifyApplication } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { getKyc } from 'selectors/kyc';
import { getApplicationData } from 'selectors/getApplicationData';
import StateContainer from 'components/StateContainer';
import FormContainer from 'components/LoanForm/FormContainer';
import { KycStatus } from 'handlers/kyc';
import { getEnvironment, Environments } from 'utils/getEnvironment';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './Alloy.module.scss';

enum Status {
  Completed = 'completed',
  WaitingReview = 'waiting_review',
  DataRequest = 'data_request',
  PendingDocuments = 'pending_documents',
  PendingAction = 'pending_action',
  Running = 'running',
}

const Alloy = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { isLoading, status, journeyApplicationToken, journeyToken, kycSkipped } = useSelector(getKyc);

  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  useEffect(() => {
    if (!status) {
      (async () => {
        await dispatchWithUnwrap(verifyApplication(application!.id));
      })();
    }
    if (
      status === KycStatus.Rejected ||
      status === KycStatus.Approved ||
      status === KycStatus.ManualReview ||
      kycSkipped
    ) {
      handleNext();
    }
    return () => {
      alloy.close();
    };
  }, [application.status, status, kycSkipped]);

  const openAlloy = () => {
    const alloyInitParams = {
      key: process.env.REACT_APP_ALLOY_SDK_KEY!,
      production: getEnvironment() === Environments.Production,
      color: { primary: '#795af7', secondary: '#454b54' },
      journeyApplicationToken,
      journeyToken,
      isNext: true,
    };
    alloy.init(alloyInitParams);
    alloy.open(callback);
  };

  // Callback
  const callback = (data: any) => {
    analytics.track(`Alloy ${data.status}`);
    if (data.status !== Status.PendingDocuments) {
      handleNext();
    }
  };

  if (isLoading) {
    return (
      <div className={styles.container}>
        <StateContainer icon={<LoadingSpinner />} title="Verifying..." />
      </div>
    );
  }

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title="Upload Driver License"
        subtitle="Plannery is partnered with Alloy to verify your identity."
        className={styles.formContainer}
      >
        <Button className={styles.button} onClick={openAlloy}>
          Get Started
        </Button>
      </FormContainer>
    </>
  );
};

export default Alloy;
