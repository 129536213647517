/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';

const useRotateCard = (sensitivity = 2) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const prevAngle = useRef(0);
  const currentAngle = useRef(0);

  useEffect(() => {
    const body = document.querySelector('body');
    if (cardRef.current) {
      const card = cardRef.current;

      card.addEventListener('touchstart', (e) => {
        const x = e.touches[0].clientX;

        window.addEventListener('touchmove', rotate);
        function rotate(rotateEvent: TouchEvent) {
          currentAngle.current = (rotateEvent.touches[0].clientX - x) / sensitivity;

          card.style.transform = `rotateY(${prevAngle.current + currentAngle.current}deg)`;

          // Change cursor style
          card.style.cursor = 'grabbing';
          body!.style.cursor = 'grabbing';
          body!.classList.add('disableSelect');
        }

        prevAngle.current += currentAngle.current;

        window.addEventListener('touchend', () => {
          window.removeEventListener('touchmove', rotate);

          // Change cursor style
          card.style.cursor = 'grab';
          body!.style.cursor = 'default';
          body!.classList.remove('disableSelect');
        });
      });

      card.addEventListener('mousedown', (e) => {
        const x = e.clientX;

        window.addEventListener('mousemove', rotate);
        function rotate(rotateEvent: MouseEvent) {
          currentAngle.current = (rotateEvent.clientX - x) / sensitivity;

          card.style.transform = `rotateY(${prevAngle.current + currentAngle.current}deg)`;

          // Change cursor style
          card.style.cursor = 'grabbing';
          body!.style.cursor = 'grabbing';
          body!.classList.add('disableSelect');
        }

        prevAngle.current += currentAngle.current;

        window.addEventListener('mouseup', () => {
          window.removeEventListener('mousemove', rotate);

          // Change cursor style
          card.style.cursor = 'grab';
          body!.style.cursor = 'default';
          body!.classList.remove('disableSelect');
        });
      });
    }
  }, [cardRef]);

  return { cardRef };
};

export default useRotateCard;
