import React from 'react';
import Button from 'components/Button';
import FormContainer from 'components/LoanForm/FormContainer';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { useDispatch } from 'react-redux';
import { setYourGoalData } from 'handlers/yourGoal';

import styles from './YourGoal.module.scss';

const goalOptions = [
  {
    id: 'consolidate_debt',
    label: 'Consolidate my debt',
  },
  {
    id: 'get_out_faster',
    label: 'Get out of debt faster',
  },
  {
    id: 'lower_payment',
    label: 'Lower my payment',
  },
  {
    id: 'lower_interest_rate',
    label: 'Get a lower interest rate',
  },
  {
    id: 'all_of_above',
    label: 'All of the above',
  },
];

const YourGoal = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatch = useDispatch();

  const handleContinue = (goal: string) => () => {
    dispatch(setYourGoalData({ goal }));
    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="What would help most?" subtitle="We'll help you reach your goals.">
        {goalOptions.map((goal) => (
          <div key={goal.id} className={styles.button}>
            <Button onClick={handleContinue(goal.label)} type={ButtonType.OptionButton}>
              {goal.label}
            </Button>
          </div>
        ))}
      </FormContainer>
    </>
  );
};

export default YourGoal;
