import React from 'react';
import BackLink from 'components/BackLink';
import { ReactComponent as LocationIcon } from 'images/location-icon.svg';
import { useSelector } from 'react-redux';
import { getYourAddressData } from 'selectors/yourAddress';
import { getCountryStateLabel } from 'utils/getCountryStateLabel';
import { getYourContactData } from 'selectors/getYourContact';

import styles from './OutOfState.module.scss';

const OutOfState = (): JSX.Element => {
  const { argyle_state: state } = useSelector(getYourAddressData);
  const { email } = useSelector(getYourContactData);

  return (
    <>
      <BackLink />
      <div className={styles.container}>
        <h1 className={styles.title}>We're not yet lending in {getCountryStateLabel(state)}</h1>
        <LocationIcon />
        <span className={styles.text}>We will notify you once we are live at</span>
        <span className={styles.email}>{email}</span>
      </div>
    </>
  );
};

export default OutOfState;
