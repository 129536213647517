import React, { useCallback, useEffect, useState } from 'react';

import { usePlaidLink, PlaidLinkOnSuccess, PlaidLinkOptions } from 'react-plaid-link';
import { getLinkToken, setBankAccounts, getCheckingAccounts } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'hooks/useNavigate';
import { RootState } from 'handlers';

import Button from 'components/Button';
import { RoutePath } from 'enums/Routes';

import { setPublicToken } from 'handlers/plaidPublicToken';
import { ReactComponent as PlaidIdentity } from 'images/plaid-identity.svg';
import { ReactComponent as PlaidDeposit } from 'images/plaid-deposit.svg';
import { ReactComponent as PlaidApproval } from 'images/plaid-approval.svg';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { CurrentFlow } from 'enums/CurrentFlow';

import styles from './Plaid.module.scss';

enum PlaidEvent {
  HANDOFF = 'HANDOFF',
}

const PlaidLink = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const [token, setToken] = useState<string | null>(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { application } = useSelector((state: RootState) => state.applicationData!);
  if (!application) {
    throw new Error('Missing application data');
  }

  const applicationId = application.id;
  const isMissedPaymentFlow = application.currentFlow === CurrentFlow.MissedPayment;

  useEffect(() => {
    let mounted = true;

    if (application.plaidTokenLastUpdated) {
      const lastUpdated = new Date(application.plaidTokenLastUpdated!);
      const now = new Date();
      const diff = Math.abs(now.getTime() - lastUpdated.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      if (diffDays < 30) {
        handleNext();
        return;
      }
    }

    const createLinkToken = async () => {
      const response = await dispatchWithUnwrap(getLinkToken());
      const { LINK_TOKEN: linkToken } = response;
      mounted && setToken(linkToken);
    };

    createLinkToken();

    return () => {
      mounted = false;
    };
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(async (plaidPublicToken: string) => {
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    await dispatchWithUnwrap(setBankAccounts({ publicToken: plaidPublicToken, applicationId }));
    dispatch(getCheckingAccounts({ applicationId, publicToken: plaidPublicToken }));
    dispatch(setPublicToken({ publicToken: plaidPublicToken }));
  }, []);

  const handleContinue = async (evtName: string) => {
    if (evtName !== PlaidEvent.HANDOFF) {
      return;
    }
    handleNext();
  };

  const handleExit = () => {
    navigate(RoutePath.BankAccount);
  };

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onExit: handleExit,
    onEvent: handleContinue,
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <h1 className={styles.title}>Connect your checking account</h1>
        <p className={styles.subtitle}>This is the account where you deposit your paychecks and pay bills.</p>
        <div className={styles.descriptionContainer}>
          <div className={styles.descriptionItems}>
            <div className={styles.plaidItem}>
              <PlaidIdentity className={styles.icon} />
              <div className={styles.itemText}>
                {isMissedPaymentFlow
                  ? 'Make your loan payment with one click. No typos or long numbers to enter.'
                  : 'The fastest way to verify your identity.'}
              </div>
            </div>
            <div className={styles.plaidItem}>
              <PlaidDeposit className={styles.icon} />
              <div className={styles.itemText}>
                {isMissedPaymentFlow
                  ? 'Ensure there are funds in the account to avoid overdraft fees.'
                  : 'Confirm the bank account we should deposit your funds into.'}
              </div>
            </div>
            <div className={styles.plaidItem}>
              <PlaidApproval className={styles.icon} />
              <div className={styles.itemText}>
                {isMissedPaymentFlow
                  ? 'Cancel authorization anytime.'
                  : 'Receive your funds in 3 business days after approval.'}
              </div>
            </div>
          </div>
        </div>
        <Button className={styles.button} onClick={() => open()} isLoading={!ready}>
          Link my account
        </Button>
        <div className={styles.notification}>Secured with bank level security protocols</div>
      </div>
    </>
  );
};

export default PlaidLink;
