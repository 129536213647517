export enum LocalStorageKeyName {
  Location = 'location',
  PreQualification = 'preQualification',
  PreQualificationResult = 'preQualificationResult',
  ApplicationStep = 'applicationStep',
  LoanOfferResult = 'loanOfferResult',
  AboutYou = 'aboutYou',
  YourDetails = 'yourDetails',
  YourIncome = 'yourIncome',
  CreditReport = 'creditReport',
  PreQualificationDecline = 'preQualificationDecline',
  ApplicationState = 'applicationState',
  LinkToken = 'linkToken',
  PublicLinkToken = 'pulibcLinkToken',
}
