import React, { FC } from 'react';

import ReactDatePicker from 'react-datepicker';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import InputError from 'components/InputError';

import styles from './DatePicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerProps {
  onChange: (date: Date) => void;
  selected?: Date | null;
  className?: string;
  placeHolder?: string;
  label?: string;
  value?: string;
  // isLoading?: boolean;
  disabled?: boolean;
  name?: string;
  errorMessage?: string;
  minDate?: Date;
  maxDate?: Date;
}

const DatePicker: FC<DatePickerProps> = ({
  onChange,
  selected,
  className,
  placeHolder,
  label,
  value,
  disabled,
  name,
  errorMessage,
  minDate,
  maxDate,
}) => (
  <div className={clsx(styles.inputContainer, className)}>
    {label && <p className={styles.label}>{label}</p>}
    <ReactDatePicker
      minDate={minDate}
      maxDate={maxDate}
      isClearable
      customInput={<NumberFormat format="##/##/####" />}
      onChange={onChange}
      selected={selected}
      className={styles.input}
      placeholderText={placeHolder}
      value={value}
      disabled={disabled}
      name={name}
      showYearDropdown
      dropdownMode="select"
    />
    {!!errorMessage && <InputError errorMessage={errorMessage} />}
  </div>
);

export default DatePicker;
