import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MainLayout from 'components/MainLayout/MainLayout';
import { RoutePath } from 'enums/Routes';
import { Themes } from 'styles/themes/ThemeTypes';

import ThemeContext from './ThemeContext';

const renderConfettiBackground = (path: string) => {
  if (path === RoutePath.ConfirmLoan) {
    return true;
  }
};

const EntryPoint = () => {
  const location = useLocation();
  const [theme, setTheme] = useState(Themes.BLUE);
  const value = { theme, setTheme };

  return (
    <ThemeContext.Provider value={value}>
      <MainLayout confettiBackground={renderConfettiBackground(location.pathname)}>
        <Outlet />
      </MainLayout>
    </ThemeContext.Provider>
  );
};

export default EntryPoint;
