import { LoanTypeHumanized, Tradeline } from 'handlers/applicationData';

export interface Category {
  title: string;
  apr: number;
  accounts: Account[];
}

export interface Account {
  logo: string;
  name: string;
  amount: number;
}

export const getTotalAmountForCategory = (tradelines: Tradeline[]): number =>
  tradelines.reduce((total: number, tradeline) => total + tradeline.balance, 0);

const debtColor: { [key in LoanTypeHumanized]: string } = {
  [LoanTypeHumanized.PersonalLoan]: '#D77EF7',
  [LoanTypeHumanized.CreditCard]: '#86B4F9',
  [LoanTypeHumanized.MedicalLoan]: '#ED69C0',
  [LoanTypeHumanized.StudentLoan]: '#7D89FA',
  [LoanTypeHumanized.AutoLoan]: '#49DED5',
  [LoanTypeHumanized.Mortgage]: '#ED69C0',
  [LoanTypeHumanized.Other]: '#ED69C0',
};

export const getSparkLineColor = (debtName: LoanTypeHumanized): string => debtColor[debtName];
