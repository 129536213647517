import FormNavigation from 'components/FormNavigation';
import React, { useEffect, useState } from 'react';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { YourNameInputLabel } from 'components/LoanForm/YourName/YourName';

import { ReactComponent as BlackCard } from 'images/black-card-front.svg';
import { ReactComponent as BlackCardBack } from 'images/black-card-back.svg';
import { ReactComponent as BlueCard } from 'images/blue-card-front.svg';
import { ReactComponent as BlueCardBack } from 'images/blue-card-back.svg';
import { ReactComponent as WhiteCard } from 'images/white-card-front.svg';
import { ReactComponent as WhiteCardBack } from 'images/white-card-back.svg';
import { ReactComponent as CardFlipEllipse } from 'images/card-flip-ellipse.svg';
import { ReactComponent as HandPointing } from 'images/hand-pointing.svg';

import clsx from 'clsx';

import { useDispatch, useSelector } from 'react-redux';
import { setAboutYouData } from 'handlers/yourName';
import { useForm } from 'react-hook-form';
import { getYourNameData } from 'selectors/yourName';
import { AboutYouVariable } from 'enums/LoanFormVariables';
import { getMessageForRequiredFields } from 'utils/errors';
import useLayoutTheme from 'hooks/useLayoutTheme';
import { Themes } from 'styles/themes/ThemeTypes';
import FormContainer from 'components/LoanForm/FormContainer/FormContainer';
import useRotateCard from 'hooks/useRotateCard';
import { getCardData } from 'selectors/getCardData';
import { setCardData } from 'handlers/cardData';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './Customize.module.scss';

export enum CardColor {
  Black = 'black',
  Blue = 'blue',
  White = 'white',
}

const cardColorTheme = {
  [CardColor.Black]: Themes.BLUE,
  [CardColor.Blue]: Themes.DEFAULT,
  [CardColor.White]: Themes.DARK,
};

const Cards = {
  [CardColor.Black]: <BlackCard />,
  [CardColor.Blue]: <BlueCard />,
  [CardColor.White]: <WhiteCard />,
};

const selectedCard = (selectedColor: CardColor) => {
  switch (selectedColor) {
    case CardColor.Black:
      return {
        front: <BlackCard />,
        back: <BlackCardBack />,
      };
    case CardColor.Blue:
      return {
        front: <BlueCard />,
        back: <BlueCardBack />,
      };
    case CardColor.White:
      return {
        front: <WhiteCard />,
        back: <WhiteCardBack />,
      };
    default:
      return {
        front: <BlackCard />,
        back: <BlackCardBack />,
      };
  }
};

const Customize = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatch = useDispatch();
  const { cardColor } = useSelector(getCardData);
  const [selectedColor, setSelectedColor] = useState(cardColor ?? CardColor.Black);
  const { theme, setTheme } = useLayoutTheme();

  const { cardRef: flipCardRef } = useRotateCard();

  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);

  const {
    register,
    trigger,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      first_name: firstName,
      last_name: lastName,
    },
  });

  const watcher = watch();

  const onCardClick = (card: CardColor) => {
    setSelectedColor(card);
    setTheme(cardColorTheme[card]);
    analytics.track('Card Color Selected', { color: selectedColor });
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as AboutYouVariable, event.target.value.trim());
    trigger(event.target.name as AboutYouVariable);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as AboutYouVariable, event.target.value);
    trigger(event.target.name as AboutYouVariable);
  };

  const onNext = () => {
    dispatch(setAboutYouData(watcher));
    dispatch(setCardData({ cardColor: selectedColor }));
    handleNext();
  };

  useEffect(() => {
    register(AboutYouVariable.FirstName, { required: getMessageForRequiredFields(YourNameInputLabel.FirstName) });
    register(AboutYouVariable.LastName, { required: getMessageForRequiredFields(YourNameInputLabel.LastName) });
  }, []);

  useEffect(() => {
    setTheme(cardColorTheme[selectedColor!]);
  }, []);

  return (
    <div className={styles.container}>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Customize Your Card">
        <div className={styles.container}>
          <div className={styles.inputs}>
            <Input
              inputClassName={styles.input}
              errorMessage={errors[AboutYouVariable.FirstName]?.message}
              label="First name"
              placeholder="First Name"
              name={AboutYouVariable.FirstName}
              onChange={onChange}
              onBlur={onBlur}
              value={watcher[AboutYouVariable.FirstName]}
              data-neuro-label="firstName"
            />
            <Input
              inputClassName={styles.input}
              errorMessage={errors[AboutYouVariable.LastName]?.message}
              label="Last name"
              placeholder="Last Name"
              name={AboutYouVariable.LastName}
              onChange={onChange}
              onBlur={onBlur}
              value={watcher[AboutYouVariable.LastName]}
              data-neuro-label="lastName"
            />
          </div>

          <div className={styles.cardPickerContainer}>
            <p className={styles.label}>Select color</p>
            <div className={styles.cardPickerItems}>
              {Object.values(CardColor).map((color, index) => (
                <div
                  key={`${color}-${index}`}
                  className={clsx(styles.cardItem, styles[`cardItem__${theme}`])}
                  onClick={() => onCardClick(color)}
                >
                  <div
                    className={clsx(styles.card, styles[`card__${theme}`], {
                      [styles.selected]: selectedColor === color,
                    })}
                  >
                    {Cards[color]}
                  </div>
                  <p className={styles.cardColor}>{color}</p>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.previewContainer}>
            <div className={styles.cardPreview}>
              <div className={styles.flipCardBackground} />
              <div className={clsx(styles.ellipseContainer, styles[theme])}>
                <CardFlipEllipse className={styles.ellipse} />
                <div className={styles.handPointingContainer}>
                  <HandPointing className={styles.handPointing} />
                </div>
              </div>
              <div className={styles.flipCard}>
                <div ref={flipCardRef} className={clsx(styles.flipCardInner)}>
                  <div className={styles.flipCardFront}>
                    {selectedCard(selectedColor!).front}
                    <p className={clsx(styles.cardOwnerName, styles[theme], styles.frontName)}>
                      {watcher.first_name} {watcher.last_name}
                    </p>
                  </div>
                  <div className={styles.flipCardBack}>
                    {selectedCard(selectedColor!).back}
                    <p className={clsx(styles.cardOwnerName, styles[theme], styles.backName)}>
                      {watcher.first_name} {watcher.last_name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Button disabled={!isValid} onClick={onNext} className={styles.button}>
            Next
          </Button>
        </div>
      </FormContainer>
    </div>
  );
};

export default Customize;
