import React from 'react';
import Contact from 'components/Contact';
import { useLocation, useSearchParams } from 'react-router-dom';
import Button from 'components/Button';
import { RoutePath } from 'enums/Routes';
import { useNavigate } from 'hooks/useNavigate';
import useCurrentFlow from 'hooks/useCurrentFlow';

import { ErrorType, ErrorParam } from './ErrorType';

import styles from './Error.module.scss';

interface ErrorState {
  type: ErrorType;
}

const getErrorMessageByType = (type: ErrorType) => {
  switch (type) {
    case ErrorType.CantGenerateOffer:
      return "Sorry, we couldn't create the loan offer.";
    case ErrorType.CantGetStatementFile:
      return "We couldn't retrieve the statement.";
    case ErrorType.InvalidMethodAuthentication:
      return "Sorry, we couldn't analyze your current financial profile.";

    default:
  }
  return 'Sorry, there was an unexpected error. Please, contact support.';
};

const getErrorTypeFromQueryParams = () => {
  const [searchParams] = useSearchParams();
  const statementFileError = searchParams.get(ErrorParam.StatementFile);
  if (statementFileError === null) {
    return ErrorType.Default;
  }
  return ErrorType.CantGetStatementFile;
};

const Error = () => {
  const navigate = useNavigate();
  const { state: errorState } = useLocation();

  const { isFinancialCheckupFlow } = useCurrentFlow();

  let errorType: ErrorType;
  if (errorState) {
    ({ type: errorType } = errorState as ErrorState);
  } else {
    errorType = getErrorTypeFromQueryParams();
  }

  const errorMessage = getErrorMessageByType(errorType!);

  const handleGetBack = () => {
    if (isFinancialCheckupFlow) {
      navigate(RoutePath.YourFinances);
      return;
    }
    window.open('https://www.planneryapp.com/');
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{errorMessage}</div>

      {isFinancialCheckupFlow && (
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={handleGetBack}>
            Back to Your Finances
          </Button>
        </div>
      )}

      <Contact handleGetBack={handleGetBack} />
    </div>
  );
};

export default Error;
