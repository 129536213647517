import { Analytics, Context } from '@segment/analytics-next';
import { sha256 } from 'utils/cryptoUtils';

const flags = {
  waitlistNumber: [0, 147, 1473, 14726, 147268],
};

const useFeatureFlags = () => flags;

export const assignFeatureFlags = () => {
  analytics.then(async (instance: [Analytics, Context]) => {
    const user = instance[0].user();

    const userId = user.anonymousId()!.toString();

    const flagKeys = Object.keys(flags);
    const hashes = await Promise.all(flagKeys.map((flag) => sha256('flags', userId, flag)));

    flagKeys.forEach((key, index) => {
      const flagKey = key as keyof typeof flags;
      const bucket = Number(`0x${hashes[index].substring(0, 8)}`);
      const flag = flags[flagKey];
      flags[flagKey] = [flag[bucket % flag.length]];
    });

    analytics.identify({ featureFlags: flags });
  });
};

export default useFeatureFlags;
