import React, { useEffect } from 'react';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'handlers';
import { YourBirthDateVariable } from 'enums/LoanFormVariables';
import FormContainer from 'components/LoanForm/FormContainer';
import { setYourBirthDateData } from 'handlers/yourBirthDate';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import { useForm } from 'react-hook-form';
import { getMessageForRequiredFields } from 'utils/errors';
import { formatDate } from 'utils/dateUtils';

import styles from './BirthDate.module.scss';

export enum YourBirthDateInputLabel {
  DateOfBirth = 'Date of birth',
}

const BirthDate = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatch = useDispatch();
  const { birth_date: birthDate } = useSelector((state: RootState) => state.yourBirthDate);

  const {
    formState: { errors, isValid },
    register,
    watch,
    trigger,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      birth_date: birthDate ? new Date(birthDate) : null,
    },
  });

  const watcher = watch();

  useEffect(() => {
    register(YourBirthDateVariable.DateOfBirth, {
      required: getMessageForRequiredFields(YourBirthDateInputLabel.DateOfBirth),
    });
  });

  const handleContinue = () => {
    dispatch(
      setYourBirthDateData({
        birth_date: formatDate(watcher.birth_date!),
      }),
    );
    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <FormContainer title="When were you born?" subtitle="This helps us verify your identity.">
          <div className={styles.inputs}>
            <DatePicker
              maxDate={new Date()}
              placeHolder="MM/DD/YYYY"
              label="Date of birth"
              onChange={(date: Date) => {
                setValue(YourBirthDateVariable.DateOfBirth, date);
                trigger(YourBirthDateVariable.DateOfBirth);
              }}
              selected={watcher[YourBirthDateVariable.DateOfBirth]}
              errorMessage={errors[YourBirthDateVariable.DateOfBirth]?.message}
              name={YourBirthDateVariable.DateOfBirth}
            />
          </div>
          <Button id="next-button" className={styles.button} onClick={handleContinue} disabled={!isValid}>
            Next
          </Button>
        </FormContainer>
      </div>
    </>
  );
};

export default BirthDate;
