import React, { useEffect, useState } from 'react';

import Button from 'components/Button';

import { BankAccountInfo } from 'api/PlaidApi';
import { updateApplicationCheckingAccount } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import Loader from 'components/Loader';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';

import CheckingAccountRow from './components/CheckingAccountRow';

import styles from './CheckingAccounts.module.scss';

const CheckingAccounts = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const checkingAccounts = useSelector((state: RootState) => state.bankAccount.bankAccounts)!;
  const { application } = useSelector((state: RootState) => state.applicationData!);

  const [selectedAccount, setSelectedAccount] = useState<string>('');

  const handleAccountChange = (account: BankAccountInfo) => {
    if (account.id === selectedAccount) {
      return;
    }

    setSelectedAccount(account.id);
  };

  const handleContinue = async () => {
    if (selectedAccount) {
      await saveUserSelection();
    }

    handleNext();
  };

  const saveUserSelection = async () => {
    await dispatchWithUnwrap(
      updateApplicationCheckingAccount({
        applicationId: application!.id,
        bankAccountId: selectedAccount,
      }),
    );
  };

  useEffect(() => {
    if (checkingAccounts.length > 0) {
      setIsLoading(false);
      setSelectedAccount(checkingAccounts[0].id);
    }
    // Skip checking account list if accounts are less than 2
    if (checkingAccounts.length < 2) {
      handleContinue();
    }
  }, [checkingAccounts]);

  return isLoading ? (
    <div className={styles.loaderContainer}>
      <Loader color="#795af7" />
    </div>
  ) : (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <div className={styles.title}>Select the account the where you deposit your paycheck</div>

        <div className={styles.content}>
          {checkingAccounts.map((account, index) => {
            const { officialName, mask, balance } = account;

            return (
              <CheckingAccountRow
                key={`${account.mask}-${index}`}
                selected={selectedAccount === account.id}
                onClick={() => handleAccountChange(account)}
                accountName={officialName}
                accountNumber={mask}
                accountBalance={balance}
              />
            );
          })}
        </div>

        <Button onClick={handleContinue} className={styles.button}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default CheckingAccounts;
