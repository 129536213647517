import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';

const saveApplicationDataMiddleware = ({ getState }: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (
  action: AnyAction,
) => {
  const result = next(action);

  switch (action.type) {
    case 'applicationStep/setApplicationStep':
    case 'argyle/removeArgyleConnection':
    case 'applicationData/getApplicationData/fulfilled':
    case 'preQualification/getPreQualification/fulfilled':
    case 'getCheckingAccounts/fulfilled':
    case 'applicationData/updateEmploymentData/fulfilled':
      localStorage.setItem(LocalStorageKeyName.ApplicationState, JSON.stringify(getState()));
      break;

    default:
      break;
  }

  return result;
};

export default saveApplicationDataMiddleware;
