import {
  NavigateOptions,
  useLocation,
  useNavigate as useNavigation,
  useNavigationType as internalUseNavigationType,
} from 'react-router-dom';
import { RoutePath } from 'enums/Routes';

export const useNavigate = () => {
  const navigation = useNavigation();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  params.delete('fbc');
  params.delete('fbp');
  params.delete('anonymous_id');

  return (to: RoutePath, options?: NavigateOptions) => navigation({ pathname: to, search: params.toString() }, options);
};

export const useNavigationType = internalUseNavigationType;
